import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import ClockIcon from "assets/image/clock.svg";
import CallIcon from "assets/image/call.svg";
import CircleIcon from "assets/image/circle.svg"
import MessageIcon from "assets/image/message.svg";
import { Modal, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { encryptQuery, fetchIntakeGraphqlAPI } from "utils/queries";
import { INTAKE_FRONTEND_URL } from "../../constants/common";

const insuranceColorCode = {
  HMO: "#f2831b",
  PPO: "#2ea63e",
};

const Wrapper = styled.div`
  font-family: "Poppins", sans-serif;
  .item {
    position: relative;
    cursor: pointer;
    padding: 16px;
    display: flex;
    border-bottom: 1px solid #e2eaec;
    &.active {
      background-color: #edf5fe;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 3px;
        height: 100%;
        background-color: #0c1743;
      }
    }
    .avatar {
      height: 44px;
      width: 44px;
      color: #fff;
      background-color: #0a1746;
      border-radius: 50%;
      display: grid;
      place-items: center;
      text-transform: uppercase;
      font-size: 20px;
      line-height: 30px;
      font-weight: 500;
    }
    .meta {
      margin-left: 16px;
      b {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      .dob {
        font-size: 12px;
        font-weight: 500;
        color: #70797e;
      }
      .date {
        span {
          margin-left: 4px;
          font-size: 12px;
          font-weight: 500;
          color: #70797e;
        }
      }
    }
  }
  .unreadCount {
    position: absolute;
    right: 16px;
    background-color: #0a1746;
    height: 16px;
    font-size: 10px;
    color: #fff;
    border-radius: 15px;
    padding: 0 5px;
    display: grid;
    place-items: center;
    line-height: 15px;
    font-weight: 600;
  }
`;

const requestStatusBgColor = 
  {
    "Clinical": "#eb34b4",
    "Request": "#db9a0d",
    "Urgent": "red",
    "Billing": "#6c6e05",
    "Scheduling": "#b54905",
    "Referral": "#4287f5"
  }
const unassignedChatSource = {
  'CONTACTUS': "Contact us",
  'CHATBOT': "Chatbot"
}

function initials(str) {
  let result = "";
  if (str) {
    let splitArr = str.split(" ");
    splitArr.forEach((e) => {
      result += e.substr(0, 1);
    });
  }
  return result;
}

const ConversationList = ({
  data,
  activeThread,
  organizationId,
  updateActiveThread,
  markUnreadAsRead,
  conversationId,
  orgDetails,
}) => {
  const [newPatientCallModalVisible, setNewPatientCallModalVisible] =
    useState(false);
  const [newPatientMsgModalVisible, setNewPatientMsgModalVisible] =
    useState(false);
  const [messageContent, setMessageContent] = useState();
  const [callContent, setCallContent] = useState();
  const [appointmentLink, setAppointmentLink] = useState("");

  let initialMsgContent = `Welcome to ${orgDetails?.name}, we're thrilled to have you reach out! Here are some resources to get you started: Book an appointment easily by clicking here 
  ${appointmentLink} 
    Looking forward to assisting you on your health journey!`;
  let initialCallText = `Sorry, we can't provide detailed assistance via this channel. Please call our office at ${orgDetails?.phoneNumber} for further guidance. We're here to help!`;

  async function copyToClipboardForCall() {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(callContent || initialCallText);
    } else {
      document.execCommand("copy", true, callContent || initialCallText);
    }

    message.success("Copied!");
    setNewPatientCallModalVisible(false);
    setCallContent(initialCallText);
  }

  const copyToClipboardForMessage = async () => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(messageContent || initialMsgContent);
    } else {
      document.execCommand("copy", true, messageContent || initialMsgContent);
    }

    message.success("Copied!");
    setNewPatientMsgModalVisible(false);
    setMessageContent(initialMsgContent);
  };

  const handleCancel = () => {
    if (newPatientCallModalVisible) {
      setNewPatientCallModalVisible(false);
      setCallContent(initialCallText);
    } else {
      setNewPatientMsgModalVisible(false);
      setMessageContent(initialMsgContent);
    }
  };

  async function handleCreatApptLink() {
    const encryptedOrganizationId = await fetchIntakeGraphqlAPI(encryptQuery, {
      string: organizationId,
    });

    const consultReqUrl = `${INTAKE_FRONTEND_URL}/${encryptedOrganizationId.encrypt}`;
    setAppointmentLink(consultReqUrl);
  }

  useEffect(() => {
    const active = data?.filter((item) => item?._id === conversationId);
    if (active?.length) {
      handleClick(active[0]);
    }
    // eslint-disable-next-line
  }, [data?.length]);

  function handleClick(obj) {
    markUnreadAsRead(obj);
    updateActiveThread({ chatThread: null, loading: true });
    setTimeout(() => {
      updateActiveThread({
        chatThread: obj,
        loading: false,
      });
    }, 100);
  }
  return (
    <>
      <Wrapper>
        {data && data.length ? data?.map((item) => (
          <div
            className={`item ${
              activeThread?.chatThread?._id === item._id ? "active" : ""
            }`}
            key={item._id}
            onClick={() => handleClick(item)}
            title={
              item.insuranceType
                ? item.insuranceType
                : item?.cashPay
                ? "cash-pay"
                : ""
            }
          >
            <div className="avatar">{initials(item.patientName)}</div>
            <div className="meta">
              <b
                style={{
                  color: item.insuranceType
                    ? insuranceColorCode[item.insuranceType]
                    : "",
                    display: "flex"
                }}
              >
                {item.patientName}{" "} {item.status === "unassigned"  ? <div title={`Unassigned (${item.from ? unassignedChatSource[item.from] : 'Unknown Patient'}) `} ><img src={CircleIcon} alt="" /></div>: null}
              </b>

              <div className="dob">{item.birthdate}</div>
              <div
                className="date"
                style={{ display: "flex", alignItems: "center" }}
              >
                <img src={ClockIcon} alt="" />
                <span>{moment(item.updatedAt).fromNow()}</span>
                {item.requestType ? (
                  <span
                    style={{
                      padding: "3px",
                      borderRadius: "5px",
                      background: `${requestStatusBgColor[item?.requestType]}`,
                      color: "#fff",
                    }}
                  >
                    {item.requestType}
                  </span>
                ) : null}
                {item.status === "unassigned" ? (
                  <div style={{ display: "flex", marginLeft: "2px" }}>
                    <div title="NEWPT-WELCOME" style={{ margin: "5px" }}>
                      <img
                        src={MessageIcon}
                        alt="message"
                        onClick={() => {
                          setNewPatientMsgModalVisible(true);
                          handleCreatApptLink();
                        }}
                      />
                    </div>

                    <div title="NEWPT-CALL">
                      <img
                        src={CallIcon}
                        alt="call"
                        onClick={() => {
                          setNewPatientCallModalVisible(true);
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            {item.unreadCount > 0 && item.unreadCount && (
              <div className="unreadCount">{item.unreadCount}</div>
            )}
          </div>
        )): null}
      </Wrapper>

      <Modal
        visible={newPatientMsgModalVisible}
        title="Copy text to send message to new patient"
        onOk={copyToClipboardForMessage}
        onCancel={handleCancel}
        okText="Copy Text"
        cancelText="Cancel"
      >
        <TextArea
          style={{ textAlign: "justify", whiteSpace: "normal" }}
          value={messageContent || initialMsgContent}
          rows={6}
          onChange={(e) => setMessageContent(e.target.value)}
        ></TextArea>
      </Modal>

      <Modal
        visible={newPatientCallModalVisible}
        title="Copy text to send message to new patient"
        onOk={copyToClipboardForCall}
        onCancel={handleCancel}
        okText="Copy Text"
        cancelText="Cancel"
      >
        <TextArea
          style={{ textAlign: "left", whiteSpace: "normal" }}
          value={callContent || initialCallText}
          rows={6}
          onChange={(e) => setCallContent(e.target.value)}
        ></TextArea>
      </Modal>
    </>
  );
};

export default ConversationList;

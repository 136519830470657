import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
// import { withRouter, Link } from "react-router-dom";
import styled from "styled-components";
import {
  // Mentions,
  Layout,
  Table,
  Typography,
  Button,
  Row,
  Col,
  message,
  Empty,
  Spin,
  Upload,
  Avatar,
  Space,
  Switch,
  Image,
  Popover,
  Tooltip,
  Alert,
  Modal,
  Radio,
} from "antd";
import {
  CloseCircleTwoTone,
  // FileImageOutlined,
  LockOutlined,
  // CalendarOutlined,
  // EyeOutlined,
  // SolutionOutlined,
  // FileAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import AddCannedMessageModal from "./AddCannedMessageModal";
import { newMessageReceived } from "../../services/socket";
import html2pdf from "html2pdf.js";

import filter from "lodash/filter";
import moment from "moment";
import APIService from "../../services/api";
// import StorageService from "../../services/storage";
import audioFile from "../../assets/steerAlert.wav";
import EmojiIcon from "../../assets/image/Emoji.svg";
import AttachmentIcon from "../../assets/image/attachment.svg";
// import DocumentAddIcon from "../../assets/image/document-add.svg";
import UnreadIcon from "../../assets/image/unread.svg";
// import CalendarIcon from "../../assets/image/calendar.svg";
// import EyeIcon from "../../assets/image/ion_eye.svg";
import MarkRequestIcon from "assets/image/mark.svg";
import CallIcon from "assets/image/call.svg";
import StaffAssignIcon from "assets/image/staffAssign.svg";
import ChatDownloadIcon from "../../assets/image/download.svg";
import FormsIcon from "../../assets/image/forms.svg";
import Picker from "emoji-picker-react";
import { MentionsInput, Mention } from "react-mentions";

// import "./style.less";
const { Header, Footer } = Layout;
const { Text } = Typography;
// const { Option, getMentions } = Mentions;

const Wrapper = styled.div`
  width: 100%;
  font-family: "Poppins", sans-serif;
  .chat-conversation-header {
    position: relative;
    z-index: 1;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
    padding: 0 24px;
    .patientName {
      font-size: 16px;
      display: flex;
      strong {
        display: flex;
        flex-flow: column;
        justify-content: center;
        line-height: initial;
        .dob {
          font-size: 14px;
          color: #70797e;
        }
      }
    }
    .conversation-actions {
      gap: 12px !important;
      button {
        border-radius: 10px;
        background-color: #f7f8fc;
        border: 0;
        height: 34px;
        width: 34px;
      }
    }
  }
  .avatar {
    .ant-typography {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #202123;
    }
  }
  .chat-conversation {
    overflow: auto;
    flex-grow: 1;
    .scrollable {
      height: auto;
      .message-col {
        max-width: 50%;
      }
    }
  }
  @keyframes rotateShadow {
    0% { box-shadow: 0 0 5px #f4aab9; } /* Starting shadow */
    33% { box-shadow: 2px 2px 2px #f4aab9; } /* Midpoint shadow */
    66% { box-shadow: -2px 2px 2px #f4aab9; } /* Another midpoint shadow */
    100% { box-shadow: 0 0 5px #f4aab9; } /* Ending shadow */
  }

  .btn-effect {
    background-color: red;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 5px #ff4500;
    animation: rotateShadow 2s infinite linear;
  }
  .chat-conversation-footer {
    display: flex;
    .leftSection {
      display: flex;
      align-items: center;
      margin-right: 20px;
      .itemWrapper {
        padding: 0 12px;
      }
    }
    .rightSectionWrapper {
      .rightSection {
        display: flex;
        align-items: center;
        .itemWrapper {
          cursor: pointer;
          padding: 0 12px;
        }
      }

      .internalChatWrapper {
        display: flex;
        align-items: center;
        margin-left: 1rem;
        margin-top: 0.25rem;
        gap: 0.5rem;

        input[type="checkbox"] {
          width: 18px;
          height: 18px;
        }

        label {
          font-weight: 500;
          font-size: 16px;
          color: #70797e;
        }
      }
    }
    textarea {
      font-size: 16px;
      &::placeholder {
        font-size: 14px;
      }
    }
  }

  .mentionTextArea {
    overflow-x: hidden;
    .mentionTextArea__highlighter {
      height: 60px !important;
      min-height: 35px;
      max-height: 160px !important;
      overflow-y: auto !important;
      resize: none !important;
    }

    .mentionTextArea__suggestions {
      border: 1px solid none;
      border-radius: 2px;
      color: rgba(0, 0, 0, 0.85);
      max-height: 256px;
      overflow-y: hidden;
      overflow-anchor: none;
      width: 100%;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);

      li {
        min-height: 32px;
        padding: 5px 12px;
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        flex: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      li:hover {
        background-color: #f5f5f5;
      }
    }

    textarea {
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      padding: 4px 11px;
      transition: all 0.3s, height 0s;
      height: 60px !important;
      min-height: 35px;
      max-height: 160px !important;
      overflow-y: auto !important;
      resize: none !important;
    }

    textarea::placeholder {
      color: #dcdcdc;
    }

    textarea:focus {
      border-color: #182752;
      border-right-width: 1px !important;
      outline: 0;
      box-shadow: 0 0 0 2px rgba(10, 23, 70, 0.2);
    }

    textarea:hover {
      border-color: #182752;
      border-right-width: 1px !important;
    }
  }

  .close-btn {
    position: relative;
    right: 24px;
    top: 8px;
    height: 12px;
  }
`;

const Content = styled.div`
  background-color: #fff;
  flex: auto;
  min-height: 0;
`;

let globalChatMessagesData;
let globalChatThread;
function initial(str) {
  let result = "";
  if (str) {
    let splitArr = str.split(" ");
    splitArr.forEach((e) => {
      result += e.substr(0, 1);
    });
  }
  return result;
}
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    // var intlCode = match[1] ? "+1 " : "";
    return ["(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}
const AssignPatientListToIndividualMessage = ({
  organizationId,
  conversationId,
  phoneNumber,
  callback,
  messageId,
}) => {
  const [data, updateData] = useState([]);
  const [assignedPatientToChat, setAssignedPatientToChat] = useState({});
  useEffect(() => {
    if (phoneNumber) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);

  useEffect(() => {
    getConversationById();
  }, []);

  const getConversationById = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/get-conversation-by-id?conversationId=${conversationId}&organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        setAssignedPatientToChat(res.data);
      }
    });
  };

  let assignedPatientToConversation =
    assignedPatientToChat && assignedPatientToChat.length
      ? assignedPatientToChat[0].patientId
      : null;

  const getData = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/patient-with-same-number?phoneNumber=${phoneNumber.replace(
        "+1",
        ""
      )}&organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateData(res.data);
      }
    });
  };
  const assignMessage = (patientId) => {
    APIService.chatwootAPI("api/v1/chatwoot/assign-message", "post", {
      conversationId,
      organizationId,
      patientId,
      messageId,
      phoneNumber,
    })
      .then(({ res }) => {
        if (res && res.code === "OK") {
          message.success("Chat message as been assigned to selected patient.");
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        alert("Oops!! couldn't assign this message to a patient");
      });
  };
  let columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      render: (text, record) => {
        return <span>{`${record.firstName} ${record.lastName}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (text) => {
        return (
          <Button
            size="small"
            onClick={() => assignMessage(text)}
            type="primary"
          >
            Assign
          </Button>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={data.filter(
        (curr) => curr._id !== assignedPatientToConversation
      )}
      columns={columns}
      pagination={{ hideOnSinglePage: true }}
    ></Table>
  );
};
const AssignPatientList = ({
  organizationId,
  conversationId,
  phoneNumber,
  callback,
  setAssignPatientPopoverVisible
}) => {
  const [data, updateData] = useState([]);
  useEffect(() => {
    if (phoneNumber) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber]);
  const getData = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/patient-with-same-number?phoneNumber=${phoneNumber.replace(
        "+1",
        ""
      )}&organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateData(res.data);
      }
    });
  };
  const assignConversation = (patientId) => {
    APIService.chatwootAPI("api/v1/chatwoot/assign-conversation", "post", {
      conversationId,
      organizationId,
      patientId,
    })
      .then(({ res }) => {
        if (res && res.code === "OK") {
          setAssignPatientPopoverVisible(false);
          message.success(
            "Chat conversation as been assigned to selected patient."
          );
          if (callback) {
            callback();
          }
        }
      })
      .catch((error) => {
        alert("Oops!! couldn't assign this conversation to a patient");
      });
  };
  let columns = [
    {
      title: "Patient Name",
      dataIndex: "firstName",
      render: (text, record) => {
        return <span>{`${record.firstName} ${record.lastName}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (text) => {
        return (
          <Button
            size="small"
            onClick={() => assignConversation(text)}
            type="primary"
          >
            Assign
          </Button>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={{ hideOnSinglePage: true }}
    ></Table>
  );
};

const MarkRequest = ({
  organizationId,
  conversationId,
  setRequestStatusPopoverVisible,
  setIsRequestAssign,
  markAsUnread,
  chatThread,
}) => {
  const request = [
    { status: "Urgent" },
    { status: "Request" },
    { status: "Clinical" },
    { status: "Billing" },
    { status: "Scheduling" },
    { status: "Referral" },
  ];

  const requestStatusBgColor = {
    Clinical: "#eb34b4",
    Request: "#db9a0d",
    Urgent: "red",
    Billing: "#6c6e05",
    Scheduling: "#b54905",
    Referral: "#4287f5",
  };

  const closePopOver = () => {
    setRequestStatusPopoverVisible(false);
    setIsRequestAssign(true);
  };
  const handleAssignRequest = (requestType) => {
    try {
      APIService.chatwootAPI(
        `api/v1/chatwoot/mark-conversation-request?organizationId=${organizationId}`,
        "post",
        {
          conversationId,
          requestType: requestType.status,
        }
      );
      message.success("Request has been marked successfully");
      chatThread.requestType = requestType.status;
      markAsUnread(chatThread);
      closePopOver();
    } catch (err) {}
  };
  let columns = [
    {
      title: "Request",
      dataIndex: "Name",
      render: (text, record) => {
        return <span>{`${record.status}`}</span>;
      },
    },
    {
      title: "Action",
      dataIndex: "_id",
      render: (text, record) => {
        return (
          <Button
            size="small"
            onClick={() => handleAssignRequest(record)}
            type="primary"
            style={{
              background: `${requestStatusBgColor[record.status]}`,
              color: "#fff",
              border: "none",
            }}
          >
            Mark
          </Button>
        );
      },
    },
  ];
  return (
    <Table
      dataSource={request}
      columns={columns}
      pagination={{ hideOnSinglePage: true }}
    ></Table>
  );
};
export default function ChatConversation({
  organizationId,
  userId,
  chatThread,
  callback,
  markAsUnread,
  setIsRequestAssign,
  assignedFilter,
  selectedConversationStatus,
  setIsStaffAssign,
}) {
  // let userToken = StorageService.getData("token");
  const [assignPatientPopoverVisible, setAssignPatientPopoverVisible] =
    useState(false);
  const [
    assignPatientToMessagePopoverVisible,
    setAssignPatientToMessagePopoverVisible,
  ] = useState(false);
  const [requestStatusPopoverVisible, setRequestStatusPopoverVisible] =
    useState(false);
  const [showChatHistoryModal, setShowChatHistoryModal] = useState(false);
  const [showStaffAssignModal, setShowStaffAssignModal] = useState(false);
  const initialData = {
    loading: false,
    endReached: false,
    pagination: {
      current: 1,
      pageSize: 10,
    },
    data: [],
  };
  const [sendMessageLoading, updateSendMessageLoading] = useState(false);
  const [isMessageSend, setIsMessageSend] = useState(false);
  const [base64, setBase64] = useState("");
  const [agents, updateAgents] = useState([]);
  const [agentsBasedOnRole, updateAgentsBasedOnRole] = useState([]);
  const [cannedMessageModal, updateCannedMessageModal] = useState({
    visible: false,
  });
  const [emoticonvisible, setEmoticonVisible] = useState(false);
  const [isMessagePrivate] = useState(false);
  const [enterToSend, setEnterToSend] = useState(false);
  const [internalChat, setInternalChat] = useState(false);
  const [closeConversationLoading, updateCloseConversationLoading] =
    useState(false);
  const [chatMessagesData, updateChatMessagesData] = useState(initialData);
  const [internalChatMessagesData, setInternalChatMessagesData] =
    useState(initialData);
  const [textMessage, updateTextMessage] = useState("");
  const [staffAssign, setStaffAssign] = useState("");
  const [attachedFiles, updateAttachedFiles] = useState([]);
  const [cannedOptions, updateCannedOptions] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [conversationThreadId, setConversationThreadId] = useState("");
  const [cannedOptionsAutoComplete, updateCannedOptionsAutoComplete] = useState(
    []
  );
  const [selectedMsgId, setSelectedMsgId] = useState(null);
  const [role, setRole] = useState("");
  const onChangeRole = (e) => {
    setRole(e.target.value);
    getAgentsBasedOnRole(e.target.value);
  };

  const messagesEndRef = useRef(null);
  const messagesTopRef = useRef(null);

  const allCannedOptionsAutoComplete = cannedOptions?.map((v) => {
    return { id: v?._id, display: v?.content };
  });
  const allUsers = agents?.map((v) => {
    return { id: v?._id, display: `@${v?.name}` };
  });
  const allUsersBasedOnRole = agentsBasedOnRole?.map((v) => {
    return { id: v?._id, display: `@${v?.name}` };
  });

  useEffect(() => {
    getCannedMessages();
    getAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // useEffect(() => {
  //   if (!cannedMessageModal.visible && cannedMessageModal.success) {
  //     getCannedMessages();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [cannedMessageModal]);
  useEffect(() => {
    globalChatThread = { ...chatThread };
    if (globalChatThread.unreadCount) {
      updateLastSeen(chatThread._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatThread]);
  const updateLastSeen = (conversationId) => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/update-last-seen?organizationId=${organizationId}`,
      "post",
      {
        organizationId,
        conversationId,
      }
    );
  };
  useEffect(() => {
    // if (chatMessagesData.data && chatMessagesData.data.length) {
    //   scrollToBottom();
    // }
    if (messagesTopRef.current) {
      messagesTopRef.current.scrollBottom = messagesTopRef.current.scrollHeight;
    }
    globalChatMessagesData = { ...chatMessagesData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMessagesData.data]);

  useEffect(() => {
    // if (internalChatMessagesData.data && internalChatMessagesData.data.length) {
    //   scrollToBottom();
    // }
    if (messagesTopRef.current) {
      messagesTopRef.current.scrollTop = messagesTopRef.current.scrollHeight;
    }
    globalChatMessagesData = { ...internalChatMessagesData };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internalChatMessagesData.data]);

  useEffect(() => {
    getChatMessages({ pagination: { ...initialData.pagination } });
    //subscribe to chat thread
    newMessageReceived((newMessage) => {
      if (globalChatThread._id === newMessage.conversationId) {
        let exist = globalChatMessagesData.data.find((v) => {
          return v._id === newMessage.message._id;
        });
        if (!exist) {
          updateChatMessagesData({
            ...globalChatMessagesData,
            data: [...globalChatMessagesData.data, newMessage.message],
          });
          setInternalChatMessagesData({
            ...globalChatMessagesData,
            data: [...globalChatMessagesData.data, newMessage.message],
          });
          if (newMessage.message.type === "incoming") {
            document.getElementById("playAudio").click();
          }

          updateLastSeen(globalChatThread._id);
        }
      }
      updateSendMessageLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMessageSend, chatMessagesData.data.length]);

  const playAudio = () => {
    let audio = new Audio(audioFile);
    audio.play();
  };

  const getCannedMessages = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/canned-messages?organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateCannedOptions(res.data);
      }
    });
  };

  const getAgents = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/agents?organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateAgents(res.data);
      }
    });
  };

  const getAgentsBasedOnRole = (role) => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/agents-based-on-role?organizationId=${organizationId}&role=${role}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateAgentsBasedOnRole(res.data);
      }
    });
  };

  const getChatMessages = (options) => {
    // let queryString = prepareQueryParams(options);
    updateChatMessagesData({
      ...chatMessagesData,
      loading: false,
    });
    setInternalChatMessagesData({
      ...internalChatMessagesData,
      loading: true,
    });
    let queryString = `?organizationId=${organizationId}&conversationId=${chatThread._id}`;
    APIService.chatwootAPI(
      `api/v1/chatwoot/messages${queryString}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        if (
          res.data?.hasOwnProperty("patientConsent")
            ? !res.data.patientConsent
            : false
        ) {
          setIsDisable(true);
        }
        let endReached = false;
        if (!res.data.messages.length) {
          endReached = true;
          updateChatMessagesData({
            ...chatMessagesData,
            loading: false,
          });
          setInternalChatMessagesData({
            ...internalChatMessagesData,
            loading: false,
          });
          return;
        }
        if (res.data.messages && res.data.messages.length) {
          if (options.pagination.current !== 1) {
            res.data = res.data.concat(chatMessagesData.data);
          }
          let previousSender;
          const filteredChatMsg = res.data.messages.filter(
            (ele) => ele.isInternal !== true
          );
          // const filteredInternalChatMsg = res.data.messages.filter((ele) => ele.isInternal === true);
          updateChatMessagesData({
            ...chatMessagesData,
            loading: false,
            data: filteredChatMsg.map((v) => {
              let obj = {
                updatedAt: v.updatedAt,
                message: v.content,
                private: v.private,
                senderSame: v.senderName === previousSender,
                mediaUrl: v.media,
                senderName: v.senderName,
                type: v.type,
                _id: v._id,
              };
              previousSender = obj.senderName;
              return obj;
            }),
            endReached,
            pagination: {
              ...options.pagination,
            },
          });

          setInternalChatMessagesData({
            ...internalChatMessagesData,
            loading: false,
            data: res.data.messages.map((v) => {
              let obj = {
                updatedAt: v.updatedAt,
                message: v.content,
                private: v.private,
                senderSame: v.senderName === previousSender,
                mediaUrl: v.media,
                senderName: v.senderName,
                type: v.type,
                _id: v._id,
              };
              previousSender = obj.senderName;
              return obj;
            }),
            endReached,
            pagination: {
              ...options.pagination,
            },
          });
        }
      }
    });
  };

  const sendMessage = async () => {
    if (!textMessage && (!attachedFiles || !attachedFiles.length)) {
      message.error("Please write a message or attach a file");
      return;
    }
    let mentions = [];
    agents.forEach((v) => {
      if (textMessage.indexOf(`@${v.name}`) > -1) {
        mentions.push({
          email: v.emails[0].address,
          name: v.name,
          _id: v._id,
        });
      }
    });
    updateSendMessageLoading(true);
    if (attachedFiles && attachedFiles.length) {
      let formData = new FormData();
      formData.append("organizationId", organizationId);
      formData.append("conversationId", chatThread._id);
      formData.append("message", textMessage.trim());
      formData.append("mentions", JSON.stringify(mentions));
      formData.append("private", isMessagePrivate);
      formData.append("patientPhoneNumber", chatThread.phoneNumber);
      formData.append("isInternal", internalChat);
      attachedFiles.forEach((v) => {
        formData.append("attachments", v);
      });
      APIService.chatwootAPI(
        "api/v1/chatwoot/send-message-file",
        "post",
        formData,
        {
          "Content-Type":
            "multipart/form-data; boundary=----WebKitFormBoundary",
        }
      )
        .then(({ res }) => {
          if (res && res.code === "OK") {
            // clear data
            // updateMessages(newMessageObj);
            updateTextMessage("");
            updateAttachedFiles([]);
            setIsMessageSend(!isMessageSend);
          }
          updateSendMessageLoading(false);
        })
        .catch((e) => {
          updateSendMessageLoading(false);
        });
    } else {
      let reqObj = {
        organizationId,
        conversationId: chatThread._id,
        message: textMessage.trim(),
        patientPhoneNumber: chatThread.phoneNumber,
        attachedFiles: attachedFiles,
        private: isMessagePrivate,
        mentions: mentions,
        isInternal: internalChat,
      };

      await APIService.chatwootAPI("api/v1/chatwoot/send-message", "post", {
        ...reqObj,
      })
        .then(({ res }) => {
          if (res && res.code === "OK") {
            // clear data
            // updateMessages(newMessageObj);
            updateTextMessage("");
            updateAttachedFiles([]);
            //  updateLastSeen(chatThread._id);
            updateSendMessageLoading(false);
            setIsMessageSend(!isMessageSend);
          }
        })
        .catch((e) => {
          updateSendMessageLoading(false);
        });
    }
  };

  const handleInternalChat = () => {
    setInternalChat(!internalChat);
  };

  // const onEmojiClick = (event, emojiObject) => {
  //   // setChosenEmoji(emojiObject);
  // };
  const MessageCard = ({ message }) => {
    let time = message.updatedAt
      ? moment(message.updatedAt).format("MMM DD, hh:mm A")
      : "";
    let direction = "start";
    let className =
      internalChat &&
        internalChatMessagesData?.data &&
        internalChatMessagesData?.data?.length
        ? "message-card internal-chat-left df fdc"
        : "message-card card-left df fdc";
    let avatarClassName = "df am js avatar";
    if (message.type === "outgoing") {
      direction = "end";
      className =
        internalChat &&
          internalChatMessagesData?.data &&
          internalChatMessagesData?.data?.length
          ? "message-card internal-chat-right df fdc"
          : "message-card card-right df fdc";
      avatarClassName = "df am je fdrr avatar";
    }
    if (message.private) {
      className += " private";
    }

    return (
      <>
        <Row justify={direction} key={message._id} className="message">
          <div className="message-col" span={12}>
            {!message.senderSame ? (
              <div className={avatarClassName} style={{ marginBottom: "10px" }}>
                <Avatar
                  style={{
                    margin:
                      message.type === "outgoing"
                        ? "0px 0px 0px 8px"
                        : "0px 8px 0px 0px",
                    color: "#ffffff",
                    backgroundColor: "#0A1746",
                    fontWeight: 500,
                    fontSize: 20,
                  }}
                  size={44}
                  shape="circle"
                  src={message.senderImage}
                >
                  {initial(message.senderName)}
                </Avatar>
                <Text>{message.senderName}</Text>
              </div>
            ) : null}

            <div
              style={{ display: "flex", alignItems: "center", gap: "2px" }}
              className="avatarClassName"
            >
              {callback && chatThread.status === "unassigned" ? (
                <Tooltip title="Assign to a patient">
                  <Popover
                    content={
                      <AssignPatientListToIndividualMessage
                        conversationId={chatThread._id}
                        organizationId={organizationId}
                        phoneNumber={chatThread.phoneNumber}
                        callback={callback}
                        messageId={selectedMsgId}
                      ></AssignPatientListToIndividualMessage>
                    }
                    title="Assign to a patient"
                    trigger="click"
                    visible={message._id === selectedMsgId && assignPatientToMessagePopoverVisible}
                    onVisibleChange={() => {
                      setSelectedMsgId(message._id);
                      setAssignPatientToMessagePopoverVisible(!assignPatientToMessagePopoverVisible)
                    }}
                  >
                    <Button
                      shape="circle"
                      style={{ margin: "5px" }}
                    >
                      <UserSwitchOutlined />
                    </Button>
                  </Popover>
                </Tooltip>
              ) : null}

              <div className={className}>
                {message.mediaUrl ? (
                  <Image
                    style={{
                      height: "auto",
                      width: "250px",
                      margin: "0 auto",
                    }}
                    src={message.mediaUrl}
                  ></Image>
                ) : null}
                <Text style={{ fontWeight: 500 }}>{message.message}</Text>
                <Text className="message-time">{time}</Text>
                {message.private ? (
                  <div className="lock-icon">
                    <LockOutlined />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Row>
      </>
    );
  };

  const DownloadMessageCard = ({ message }) => {
    let time = message.updatedAt
      ? moment(message.updatedAt).format("MMM DD, hh:mm A")
      : "";
    let direction = "start";
    let className =
      internalChat &&
        internalChatMessagesData?.data &&
        internalChatMessagesData?.data?.length
        ? "message-card internal-chat-left df fdc"
        : "message-card card-left df fdc";
    let avatarClassName = "df am js avatar";
    if (message.type === "outgoing") {
      direction = "end";
      className =
        internalChat &&
          internalChatMessagesData?.data &&
          internalChatMessagesData?.data?.length
          ? "message-card internal-chat-right df fdc"
          : "message-card card-right df fdc";
      avatarClassName = "df am je fdrr avatar";
    }
    if (message.private) {
      className += " private";
    }

    return (
      <Row key={message._id} className="message">
        <div
          className="message-col"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          {!message.senderSame ? (
            <Text style={{ fontWeight: 800 }}>{message.senderName}</Text>
          ) : null}
          <div>
            <small style={{ fontWeight: "700" }}> {time} : </small>
            {message.mediaUrl ? (
              <Image
                style={{
                  height: "auto",
                  width: "250px",
                  margin: "0 auto",
                }}
                src={message.mediaUrl}
              ></Image>
            ) : null}
            <Text>{message.message}</Text>

            {message.private ? (
              <div className="lock-icon">
                <LockOutlined />
              </div>
            ) : null}
          </div>
        </div>
      </Row>
    );
  };

  // const scrollToBottom = () => {
  //   if (messagesEndRef && messagesEndRef.current) {
  //     messagesEndRef.current.scrollIntoView({ behavior: "smooth",block: 'nearest', inline: 'start' });
  //   }
  // };
  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  const props = {
    onRemove: (file) => {
      this.setState((state) => {
        const index = state.fileList.indexOf(file);
        const newFileList = state.fileList.slice();
        newFileList.splice(index, 1);
        return {
          fileList: newFileList,
        };
      });
    },
    beforeUpload: (file) => {
      getBase64(file, (imageUrl) => {
        setBase64(imageUrl);
      });
      updateAttachedFiles([...attachedFiles, file]);
      return false;
    },
    attachedFiles,
  };
  const removeAttachedFile = (uid) => {
    let attachedFileArr = filter(attachedFiles, function (file) {
      return file.uid !== uid;
    });
    updateAttachedFiles(attachedFileArr);
  };

  const handleTextChange = (e, newValue, newPlainTextValue, mentions) => {
    const value = newPlainTextValue;
    updateTextMessage(value);
    if (
      (value === "" || value.charAt(0) !== "/") &&
      cannedOptionsAutoComplete.length > 0
    ) {
      updateCannedOptionsAutoComplete([]);
    }
  };

  const handleAddUser = (e, newValue, newPlainTextValue, mentions) => {
    const value = newPlainTextValue;
    setStaffAssign(value);
  };

  const assignStaff = async () => {
    if (!staffAssign) {
      message.error("Please select staff members");
      return;
    }
    let mentions = [];
    let staffIds = [];
    agents.forEach((v) => {
      if (staffAssign.indexOf(`@${v.name}`) > -1) {
        mentions.push({
          email: v.emails[0].address,
          name: v.name,
          _id: v._id,
        });
        staffIds.push({
          staffId: v._id,
          name: v.name
        });
      }
    });

    try {
      APIService.chatwootAPI(
        `api/v1/chatwoot/assign-to-staff?organizationId=${organizationId}`,
        "post",
        {
          organizationId,
          conversationId: conversationThreadId,
          staffId: staffIds,
          mentions: mentions,
          userId,
        }
      ).then(({ res }) => {
        if (res && res.code === "OK") {
          message.success("Staff has been assigned successfully");
          setShowStaffAssignModal(false);
          setIsStaffAssign(true);

        }
      });
    } catch (err) { }
  };

  const closeConversation = (status) => {
    updateCloseConversationLoading(true);
    APIService.chatwootAPI("api/v1/chatwoot/close-conversation", "post", {
      organizationId,
      status,
      conversationId: chatThread._id,
      patientImportId: chatThread.patientImportId,
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        message.success(res.message);
        if (callback) {
          callback();
        }
        updateCloseConversationLoading(false);
      }
    });
  };
  // const parentCallbackEvent = (event) => {
  //   let url = "http://localhost:3600";
  //   if (window.location.origin.indexOf("dev") > -1) {
  //     url = "https://app.dev.steerhealth.io";
  //   } else if (window.location.origin.indexOf("preprod") > -1) {
  //     url = "https://app.preprod.steerhealth.io";
  //   } else if (
  //     window.location.origin.indexOf("campaigns.oculushealth.com") > -1
  //   ) {
  //     url = "https://app.oculushealth.com";
  //   }
  //   if (event === "patient-detail") {
  //     window.parent.postMessage(`${event}_${chatThread.patientImportId}`, url);
  //   } else {
  //     APIService.chatwootAPI(
  //       "api/v1/patient/" + chatThread.patientImportId,
  //       "get",
  //       {}
  //     ).then(({ res }) => {
  //       if (res && res.code === "OK") {
  //         window.parent.postMessage(
  //           `${event}@|@${JSON.stringify(res.data)}`,
  //           url
  //         );
  //       }
  //     });
  //   }
  // };

  const handleCancel = () => {
    setShowChatHistoryModal(false);
  };

  const handleStaffCancel = () => {
    setShowStaffAssignModal(false);
  };

  const handleMarkAsUnread = () => {
    const reverseMsgs = chatMessagesData?.data?.reverse();
    let unreadCount = 0;
    for (let i = 0, len = reverseMsgs.length; i < len; i++) {
      if (reverseMsgs[i].type === "outgoing") {
        break;
      } else {
        unreadCount++;
      }
    }
    try {
      APIService.chatwootAPI(
        `api/v1/chatwoot/mark-conversation-as-unread?organizationId=${organizationId}`,
        "post",
        {
          organizationId,
          conversationId: chatThread._id,
          unreadCount,
        }
      );
      message.success("Latest messages marked as unread");
      chatThread.unreadCount = unreadCount;
      markAsUnread(chatThread);
    } catch (err) { }
  };

  const downloadPDF = (modalContent) => {
    // Convert images to Blobs
    const convertImagesToBlobs = (element) => {
      const images = element.querySelectorAll("img");
      images.forEach((img) => {
        fetch(img.src, {
          mode: "cors",
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Error fetching image: ${response.statusText}`);
            }
            return response.blob();
          })
          .then((blob) => {
            const blobUrl = URL.createObjectURL(blob);
            img.src = blobUrl;
          })
          .catch((error) => {
            console.error("Failed to fetch image:", error);
            // Handle the error appropriately, e.g., by setting a placeholder image
          });
      });
    };

    const element = document.createElement("div");
    element.innerHTML = ReactDOMServer.renderToString(modalContent);
    convertImagesToBlobs(element); // Convert images to Blobs
    document.body.appendChild(element);

    html2pdf(element, {
      margin: 1,
      filename: `${chatThread.patientName}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
    });
    setShowChatHistoryModal(false);
  };

  return (
    <Wrapper className="card-conversion">
      <Layout style={{ height: "100%", borderRadius: 10 }}>
        {!chatMessagesData.loading && chatThread ? (
          <>
            <button id="playAudio" type="button" hidden onClick={playAudio}>
              Play Audio
            </button>
            <Header className="chat-conversation-header">
              <Row justify="space-between">
                <Col style={{ display: "flex" }}>
                  <Text strong className="patientName">
                    <div>
                      {chatThread.patientName}{" "}
                      {chatThread.phoneNumber
                        ? `${formatPhoneNumber(chatThread.phoneNumber)}`
                        : ""}
                    </div>
                    <div style={{ display: "flex" }}>
                      {chatThread.staffAssigned &&
                      chatThread.staffAssigned.length
                        ? chatThread.staffAssigned.map((curr) => (
                            <span className="dob">@{curr.name}</span>
                          ))
                        : null}
                    </div>
                  </Text>
                </Col>
                <Col>
                  <Space className="df jsa conversation-actions">
                    {assignedFilter !== "assignedToMe" &&
                    selectedConversationStatus &&
                    !selectedConversationStatus.includes("resolved") ? (
                      <Tooltip title="Mark request as">
                        <Popover
                          content={
                            <MarkRequest
                              organizationId={organizationId}
                              conversationId={chatThread._id}
                              setIsRequestAssign={setIsRequestAssign}
                              setRequestStatusPopoverVisible={
                                setRequestStatusPopoverVisible
                              }
                              markAsUnread={markAsUnread}
                              chatThread={chatThread}
                            ></MarkRequest>
                          }
                          title="Mark request to conversation"
                          trigger="click"
                          visible={requestStatusPopoverVisible}
                          onVisibleChange={() =>
                            setRequestStatusPopoverVisible(
                              !requestStatusPopoverVisible
                            )
                          }
                        >
                          <Button shape="circle">
                            <img src={MarkRequestIcon} />
                          </Button>
                        </Popover>
                      </Tooltip>
                    ) : null}
                    <Tooltip title="Call">
                      <Button
                        onClick={() => {
                          window.location.href = `tel:${chatThread.phoneNumber}`;
                        }}
                        shape="circle"
                      >
                        <img src={CallIcon} />
                      </Button>
                    </Tooltip>
                    {selectedConversationStatus &&
                    !selectedConversationStatus.includes("resolved") ? (
                      <Tooltip title="Assign to staff">
                        <Button
                          shape="circle"
                          onClick={() => {
                            setConversationThreadId(chatThread._id);
                            setShowStaffAssignModal(true);
                          }}
                        >
                          <img src={StaffAssignIcon} />
                        </Button>
                      </Tooltip>
                    ) : null}
                    <Tooltip title="Download Chat">
                      <Button
                        shape="circle"
                        onClick={() => {
                          setShowChatHistoryModal(true);
                        }}
                      >
                        <img src={ChatDownloadIcon} />
                      </Button>
                    </Tooltip>
                    {callback && chatThread.status === "unassigned" ? (
                      <Tooltip title="Assign to a patient">
                        <Popover
                          content={
                            <AssignPatientList
                              callback={callback}
                              organizationId={organizationId}
                              conversationId={chatThread._id}
                              phoneNumber={chatThread.phoneNumber}
                              setAssignPatientPopoverVisible={
                                setAssignPatientPopoverVisible
                              }
                            ></AssignPatientList>
                          }
                          title="Assign to a patient"
                          trigger="click"
                          visible={assignPatientPopoverVisible}
                          onVisibleChange={() =>
                            setAssignPatientPopoverVisible(
                              !assignPatientPopoverVisible
                            )
                          }
                        >
                          <Button shape="circle" className="btn-effect">
                            <UserSwitchOutlined />
                          </Button>
                        </Popover>
                      </Tooltip>
                    ) : null}
                    {/* <Tooltip title="Canned Messages">
                      <Button
                        shape="circle"
                        onClick={() => {
                          updateCannedMessageModal({
                            visible: true,
                            organizationId: organizationId,
                          });
                        }}
                      >
                        <img src={DocumentAddIcon} alt="" />
                      </Button>
                    </Tooltip> */}

                    {/* <Tooltip title="Patient Detail">
                      <Button
                        shape="circle"
                        onClick={() => parentCallbackEvent("patient-detail")}
                      >
                        <img src={EyeIcon} />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Schedule Appointment">
                      <Button
                        shape="circle"
                        onClick={() =>
                          parentCallbackEvent("schedule-appointment")
                        }
                      >
                        <img src={CalendarIcon} />
                      </Button>
                    </Tooltip> */}
                    {callback ? (
                      <Tooltip
                        title={`${
                          chatThread.status === "resolved" ? "Open" : "Close"
                        } Conversation`}
                      >
                        <Button
                          shape="circle"
                          loading={closeConversationLoading}
                          onClick={() =>
                            closeConversation(
                              chatThread.status === "resolved"
                                ? "open"
                                : "resolved"
                            )
                          }
                        >
                          <img src={FormsIcon} alt="" />
                        </Button>
                      </Tooltip>
                    ) : null}
                    <Tooltip title="Mark as unread">
                      <Button shape="circle" onClick={handleMarkAsUnread}>
                        <img src={UnreadIcon} height="18px" alt="" />
                      </Button>
                    </Tooltip>
                  </Space>
                </Col>
              </Row>
            </Header>
            <Content className="chat-conversation" ref={messagesTopRef}>
              {!internalChat && (
                <div
                  className="scrollable"
                  // ref={messagesTopRef}
                  // onScroll={onScroll}
                >
                  {chatMessagesData.data && chatMessagesData.data.length ? (
                    chatMessagesData.data.map((message) => {
                      return message && Object.keys(message).length ? (
                        <MessageCard key={message._id} message={message} />
                      ) : null;
                    })
                  ) : (
                    <Empty></Empty>
                  )}
                  <div ref={messagesEndRef}></div>
                </div>
              )}

              {internalChat && (
                <div
                  className="scrollable"
                  // ref={messagesTopRef}
                  // onScroll={onScroll}
                >
                  {internalChatMessagesData.data &&
                  internalChatMessagesData.data.length ? (
                    internalChatMessagesData.data.map((message) => {
                      return message && Object.keys(message).length ? (
                        <MessageCard key={message._id} message={message} />
                      ) : null;
                    })
                  ) : (
                    <Empty></Empty>
                  )}
                  <div ref={messagesEndRef}></div>
                </div>
              )}
            </Content>
            {isDisable && (
              <Alert
                message={`${chatThread.patientName} has stopped this chatwoot`}
                type="error"
              />
            )}
            <Footer className="chat-conversation-footer">
              <div className="leftSection">
                {/* <div>
                  <Tooltip title="Private">
                    <div className="itemWrapper">
                      <Switch
                        className="is-private-switch"
                        checked={isMessagePrivate}
                        onChange={(e) => setIsMessagePrivate(e)}
                      />
                    </div>
                  </Tooltip>
                </div> */}
                <div className="emoticons-icon itemWrapper">
                  <Popover
                    style={{ padding: "0px" }}
                    overlayClassName="emoticons-popover"
                    content={
                      <Picker
                        onEmojiClick={(e, emojiObject) => {
                          if (emojiObject && emojiObject.emoji) {
                            updateTextMessage(
                              `${textMessage}${emojiObject.emoji}`
                            );
                          }
                        }}
                      />
                    }
                    title=""
                    trigger="click"
                    visible={emoticonvisible}
                    onVisibleChange={() => setEmoticonVisible(!emoticonvisible)}
                  >
                    <span style={{ cursor: "pointer" }}>
                      <img src={EmojiIcon} alt="" />
                    </span>
                  </Popover>
                </div>
              </div>
              <MentionsInput
                className="mentionTextArea"
                allowSpaceInQuery={true}
                style={{ width: "100%" }}
                placeholder="Shift + enter for new line. Start with '/' to select a Canned Response. Start with @ to tag staff user to this chat"    
                value={textMessage}
                onChange={handleTextChange}
              >
                <Mention
                  style={{
                    borderRadius: "6px",
                    fontSize: 16,
                  }}
                  trigger={"@"}
                  appendSpaceOnAdd={true}
                  data={allUsers}
                />
                <Mention
                  style={{
                    borderRadius: "6px",
                    fontSize: 16,
                  }}
                  trigger={"/"}
                  appendSpaceOnAdd={true}
                  data={allCannedOptionsAutoComplete}
                />
              </MentionsInput>
              {textMessage !== "" && (
                <span
                  onClick={() => updateTextMessage("")}
                  role="button"
                  aria-label="close-circle"
                  tabIndex="-1"
                  className="close-btn anticon anticon-close-circle ant-input-clear-icon"
                >
                  <svg
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close-circle"
                    width="1em"
                    height="1em"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm165.4 618.2l-66-.3L512 563.4l-99.3 118.4-66.1.3c-4.4 0-8-3.5-8-8 0-1.9.7-3.7 1.9-5.2l130.1-155L340.5 359a8.32 8.32 0 01-1.9-5.2c0-4.4 3.6-8 8-8l66.1.3L512 464.6l99.3-118.4 66-.3c4.4 0 8 3.5 8 8 0 1.9-.7 3.7-1.9 5.2L553.5 514l130 155c1.2 1.5 1.9 3.3 1.9 5.2 0 4.4-3.6 8-8 8z"></path>
                  </svg>
                </span>
              )}

              <div className="rightSectionWrapper">
                <div className="rightSection">
                  <div>
                    {attachedFiles && attachedFiles.length
                      ? attachedFiles.map((file) => {
                        return (
                          <Row justify="space-between" key={file.uid}>
                            <Col>
                              <div
                                style={{
                                  backgroundImage: `url('${base64}')`,
                                  height: "35px",
                                  width: "35px",
                                  position: "relative",
                                  borderRadius: "6px",
                                  backgroundSize: "cover",
                                  border: "1px solid #a19f9f",
                                }}
                              >
                                <CloseCircleTwoTone
                                  twoToneColor="#888888"
                                  style={{
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-6px",
                                  }}
                                  onClick={() => removeAttachedFile(file.uid)}
                                />
                              </div>
                            </Col>
                          </Row>
                        );
                      })
                      : null}
                  </div>

                  <div className="itemWrapper">
                    <Upload {...props}>
                      <img src={AttachmentIcon} alt="" />
                    </Upload>
                  </div>
                  <Button
                    type="primary"
                    disabled={isDisable}
                    loading={sendMessageLoading}
                    onClick={sendMessage}
                  >
                    Send
                  </Button>
                  {/* <div>
                    <Tooltip title="Enter sends message">
                      <div className="itemWrapper">
                        <Switch
                          className="is-private-switch"
                          checked={enterToSend}
                          onChange={(e) => setEnterToSend(e)}
                        />
                      </div>
                    </Tooltip>
                  </div> */}
                </div>
                <div className="internalChatWrapper">
                  <input
                    type="checkbox"
                    checked={internalChat}
                    onChange={() => handleInternalChat()}
                  />
                  <label>Internal Chat</label>
                </div>
              </div>
              {/* <Row justify="space-around" align="middle">
                <Col span={2} className="df jsb">
                  <Tooltip title="Private">
                    <div>
                      <Switch
                        className="is-private-switch"
                        checked={isMessagePrivate}
                        onChange={(e) => setIsMessagePrivate(e)}
                      />
                    </div>
                  </Tooltip>
                  <div className="emoticons-icon">
                    <Popover
                      style={{ padding: "0px" }}
                      overlayClassName="emoticons-popover"
                      content={
                        <Picker
                          onEmojiClick={(e, emojiObject) => {
                            if (emojiObject && emojiObject.emoji) {
                              updateTextMessage(
                                `${textMessage}${emojiObject.emoji}`
                              );
                            }
                          }}
                        />
                      }
                      title=""
                      trigger="click"
                      visible={emoticonvisible}
                      onVisibleChange={() =>
                        setEmoticonVisible(!emoticonvisible)
                      }
                    >
                      <span style={{ cursor: "pointer" }}>
                        <img src={EmojiIcon} />
                      </span>
                    </Popover>
                  </div>
                </Col>
                <Col span={18}>
                  <AutoComplete
                    value={textMessage}
                    style={{ width: "100%" }}
                    options={cannedOptionsAutoComplete}
                    onSelect={onSelect}
                    onSearch={handleSearch}
                  >
                    <TextArea
                      style={{
                        borderRadius: "6px",
                        height: "20px",
                      }}
                      rows={1}
                      value={textMessage}
                      onChange={(e) => updateTextMessage(e.target.value)}
                      placeholder="Shift + enter for new line. Start with '/' to select a Canned Response."
                      allowClear
                    />
                  </AutoComplete>
                </Col>
                <div>
                  {attachedFiles && attachedFiles.length
                    ? attachedFiles.map((file) => {
                        return (
                          <Row justify="space-between" key={file.uid}>
                            <Col>
                              <div
                                style={{
                                  backgroundImage: `url('${base64}')`,
                                  height: "35px",
                                  width: "35px",
                                  position: "relative",
                                  borderRadius: "6px",
                                  backgroundSize: "cover",
                                  border: "1px solid #a19f9f",
                                }}
                              >
                                <CloseCircleTwoTone
                                  twoToneColor="#888888"
                                  style={{
                                    position: "absolute",
                                    top: "-6px",
                                    right: "-6px",
                                  }}
                                  onClick={() => removeAttachedFile(file.uid)}
                                />
                              </div>
                            </Col>
                          </Row>
                        );
                      })
                    : null}
                </div>

                <Col span={2} justify="space-around" align="middle">
                  <Space>
                    <Upload {...props}>
                      <FileImageOutlined
                        style={{
                          fontSize: "18px",
                          color: "#A9A9A9",
                          cursor: "pointer",
                        }}
                      />
                    </Upload>
                    <Button
                      type="primary"
                      loading={sendMessageLoading}
                      onClick={sendMessage}
                    >
                      Send
                    </Button>
                  </Space>
                </Col>
              </Row> */}
            </Footer>
          </>
        ) : (
          <Spin spinning={chatMessagesData.loading}></Spin>
        )}
        {cannedMessageModal.visible ? (
          <AddCannedMessageModal
            modalData={cannedMessageModal}
            updateModalData={updateCannedMessageModal}
            cannedMessages={cannedOptions}
          ></AddCannedMessageModal>
        ) : null}
        <Modal
          visible={showChatHistoryModal}
          title="Chat history"
          onOk={() =>
            downloadPDF(
              <div>
                {!internalChat && (
                  <div>
                    {chatMessagesData.data && chatMessagesData.data.length ? (
                      chatMessagesData.data.map((message) => {
                        return message && Object.keys(message).length ? (
                          <DownloadMessageCard
                            key={message._id}
                            message={message}
                          />
                        ) : null;
                      })
                    ) : (
                      <Empty></Empty>
                    )}
                  </div>
                )}

                {internalChat && (
                  <div>
                    {internalChatMessagesData.data &&
                      internalChatMessagesData.data.length ? (
                      internalChatMessagesData.data.map((message) => {
                        return message && Object.keys(message).length ? (
                          <DownloadMessageCard
                            key={message._id}
                            message={message}
                          />
                        ) : null;
                      })
                    ) : (
                      <Empty></Empty>
                    )}
                  </div>
                )}
              </div>
            )
          }
          onCancel={handleCancel}
          okText="Download"
          cancelText="Cancel"
          width={1000}
        >
          <div style={{ height: "500px", overflow: "auto" }}>
            {!internalChat && (
              <div>
                {chatMessagesData.data && chatMessagesData.data.length ? (
                  chatMessagesData.data.map((message) => {
                    return message && Object.keys(message).length ? (
                      <DownloadMessageCard
                        key={message._id}
                        message={message}
                      />
                    ) : null;
                  })
                ) : (
                  <Empty></Empty>
                )}
              </div>
            )}

            {internalChat && (
              <div>
                {internalChatMessagesData.data &&
                  internalChatMessagesData.data.length ? (
                  internalChatMessagesData.data.map((message) => {
                    return message && Object.keys(message).length ? (
                      <DownloadMessageCard
                        key={message._id}
                        message={message}
                      />
                    ) : null;
                  })
                ) : (
                  <Empty></Empty>
                )}
              </div>
            )}
          </div>
        </Modal>
        <Modal
          visible={showStaffAssignModal}
          title="Assign Staff"
          onOk={assignStaff}
          onCancel={handleStaffCancel}
          okText="Assign"
          cancelText="Cancel"
          width={1000}
        >
          <div>
            <h5>Select Role</h5>
            <Radio.Group onChange={onChangeRole} value={role}>
              <Radio value={"doctor"}>Doctor</Radio>
              <Radio value={"officeAdmin"}>Office Admin</Radio>
              <Radio value={"administration"}>Administration</Radio>
              <Radio value={"erAdmin"}>ER Admin</Radio>
            </Radio.Group>
            {role !== "" && agentsBasedOnRole && !agentsBasedOnRole.length && (
              <h4>Oops ! no staff found!</h4>
            )}

            {role && agentsBasedOnRole && agentsBasedOnRole.length ? (
              <MentionsInput
                className="mentionTextArea"
                allowSpaceInQuery={true}
                style={{ width: "100%", margin: "15px", padding: "5px" }}
                placeholder="Start with @ to tag user to this chat"
                value={staffAssign}
                onChange={handleAddUser}
              >
                <Mention
                  style={{
                    borderRadius: "6px",
                    fontSize: 16,

                  }}
                  trigger={"@"}
                  appendSpaceOnAdd={true}
                  data={allUsersBasedOnRole}
                />
              </MentionsInput>
            ) : null}
          </div>
        </Modal>
      </Layout>
    </Wrapper>
  );
}

import React from "react";
import { compose } from "redux";
import { connect } from "react-redux";
// import axios from "axios";
import {
    Form,
    Card,
    Row,
    // Typography,
    Col,
    Input,
    Button,
    Layout,
    // Radio,
    // Descriptions,
    // DatePicker,
    // message,
} from "antd";
import { withRouter, Redirect } from "react-router-dom";
import APIService from "../../../services/api";
import StorageService from "../../../services/storage";
// import ForgotPassword from "./forgotpassword";
// import {
//     ShoppingOutlined,
//     UserOutlined,
//     ExperimentOutlined,
// } from "@ant-design/icons";
// import { MOBILE_NUMBER_REGEX } from "../../../constants/common";
const {  Footer, Content } = Layout;

function Login(props) {
    let token = StorageService.getData("token");
    const [loginForm] = Form.useForm();
    let code = new URLSearchParams(window.location.search).get("code");
    console.log("code", code);
    
    const signIn = (values) => {
        APIService.request("api/v1/auth/login", "post", { ...values }).then(
            ({ res, headers }) => {
                if (res && res.code === "OK") {
                    StorageService.setData("user", res.data.user);
                    StorageService.setData("token", res.data.token);
                    loginForm.resetFields();
                    props.history.push("/app/scheduler-jobs");
                }
            }
        );
    };
    // const redirectToOAuth = () => {
    //     return window.open(
    //         `https://drchrono.com/o/authorize/?redirect_uri=http://localhost:8009/drcrono/authorize-callback&response_type=code&client_id=iHWahCjK4bgDoi6tLtFyHMoLyXYMVKrMiliSdvlx`,
    //         "_blank" // <- This is what makes it open in a new window.
    //     );
    // };
    return token ? (
        <Redirect to="/app/dashboard"></Redirect>
    ) : (
        <Layout>
            <Content
                style={{ background: "blue", height: "calc(100vh - 70px)" }}
            >
                <Row
                    justify="space-around"
                    align="middle"
                    style={{ height: "100%" }}
                >
                    <Col span={6}>
                        <Card title="Login">
                            {/* <Button type="primary" onClick={redirectToOAuth}>
                                Login with drchrono
                            </Button> */}
                            <Form
                                layout="vertical"
                                form={loginForm}
                                className="login-form"
                                onFinish={signIn}
                            >
                                <Form.Item
                                    className="m-b-5"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                    ]}
                                >
                                    <Input placeholder="Email/Mobile Number" />
                                </Form.Item>
                                <Form.Item
                                    className="m-b-5"
                                    name="password"
                                    rules={[
                                        {
                                            required: true,
                                        },
                                        {
                                            min: 8,
                                        },
                                    ]}
                                >
                                    <Input
                                        type="password"
                                        placeholder="Password"
                                    />
                                </Form.Item>
                                {/* <a
                      onClick={() =>
                        updateModalData({
                          visible: true,
                        })
                      }
                    >
                      Forgot Password?
                    </a> */}
                                <div className="df jc">
                                    <Form.Item>
                                        <Button
                                            htmlType="submit"
                                            type="primary"
                                        >
                                            Login
                                        </Button>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Content>
            <Footer style={{ height: "70px" }}>
                <Row justify="space-around">
                    {" "}
                    <Col span="4">@2021Steer Health</Col>{" "}
                </Row>
            </Footer>
        </Layout>
    );
}

function mapStateToProps(state) {
    return state;
}
export default compose(withRouter, connect(mapStateToProps, null))(Login);

import axios from "../utils/Api";
//import CommonService from "./common";
import StorageService from "./storage";
import { message } from "antd";
function encodeQueryString(obj) {
  var str = [];
  for (var p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
}
const APIService = {
  request(url, method, params) {
    let apiUrl = url;
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: {},
    };
    let userToken = StorageService.getData("token");
    if (userToken) {
      reqOptions.headers.authorization = `Bearer ${userToken}`;
    }
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data, headers: res.headers };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(`${error.response.data.message}`);
        }
        if (error.message) {
          message.error(`${error.message}`);
        }
        return { res: [] };
      });
  },
  requestWithToken(url, method, params) {
    let apiUrl = `${url}`;
    if (method.toLowerCase() === "get" && params) {
      apiUrl += `?${encodeQueryString(params)}`;
    }
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: {},
    };
    reqOptions.headers["access-token"] = `NPIZGPAQvzKoi1dLiuSQ2thRV4GSKfu5`;
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data || [], headers: res.headers || {} };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
          return {};
        }
      });
  },
  chatwootAPI(url, method, params, headers) {
    let apiUrl = `${url}`;
    let reqOptions = {
      method: method,
      url: apiUrl,
      data: params,
      headers: {
        userid: StorageService.getData("userId"),
      },
    };
   
    reqOptions.headers["access-token"] = `NPIZGPAQvzKoi1dLiuSQ2thRV4GSKfu5`;
    if (headers) {
      reqOptions.headers = { ...reqOptions.headers, ...headers };
    }
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data || [], headers: res.headers || {} };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
          return {};
        }
      });
  },
  appointmentAPI(url, method, params, headers) {
    let apiUrl = `${url}`;
    let reqOptions = {
      method: method,
      url: apiUrl,
      params,
      headers: {
        organization: headers,
      },
    };

    reqOptions.headers["access-token"] = `NPIZGPAQvzKoi1dLiuSQ2thRV4GSKfu5`;
    if (headers) {
      reqOptions.headers = { ...reqOptions.headers, ...headers };
    }
    return axios(reqOptions)
      .then((res) => {
        return { res: res.data || [], headers: res.headers || {} };
      })
      .catch(function (error) {
        if (error.response && error.response.data) {
          message.error(error.response.data.message);
          return [];
        }
      });
  },
};
export default APIService;

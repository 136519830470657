import React, { useState, useEffect } from "react";
import { Empty, Row, Col } from "antd";
import AddCannedMessageModal from "./AddCannedMessageModal";
import APIService from "../../services/api";

const EmptyConversation = ({ organizationId }) => {
  const [cannedMessageModal, updateCannedMessageModal] = useState({
    visible: false,
  });
  const [cannedOptions, updateCannedOptions] = useState([]);

  useEffect(() => {
    getCannedMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCannedMessages = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/canned-messages?organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateCannedOptions(res.data);
      }
    });
  };

  return (
    <Row justify="center" align="middle" className="layout-fill">
      <Col>
        <Empty description="You do not have any chat history"></Empty>
        <div
          style={{ textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            updateCannedMessageModal({
              visible: true,
              organizationId: organizationId,
            });
          }}
        >
          <u>Add canned message</u>
        </div>
      </Col>
      {cannedMessageModal.visible && (
        <AddCannedMessageModal
          modalData={cannedMessageModal}
          updateModalData={updateCannedMessageModal}
          cannedMessages={cannedOptions}
        />
      )}
    </Row>
  );
};

export default EmptyConversation;

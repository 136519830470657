import React, { useState } from "react";
import {
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  // PlusOutlined,
} from "@ant-design/icons";
import { Space, Modal, Table, Form, Input, message } from "antd";
import APIService from "../../services/api";
export default function AddCannedMessageModal({
  modalData,
  cannedMessages,
  updateModalData,
  isAddNew
}) {
  const [form] = Form.useForm();
  const [cannedMessagesData, setCannedMessages] = useState(cannedMessages);
  const [display, setDisplay] = useState("list");
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(false);
  const onCreate = (values) => {
    setLoading(true);
    let requestObj = {
      organizationId: modalData.organizationId,
      ...values,
    };
    if (editData && editData._id) {
      APIService.chatwootAPI(`api/v1/chatwoot/update-canned-message`, "post", {
        ...requestObj,
        _id: editData._id,
      }).then(({ res }) => {
        if (res && res.code === "OK") {
          message.success(res.message);
          updateModalData({
            visible: false,
            success: true,
          });
        }
      });
    } else {
      APIService.chatwootAPI(
        `api/v1/chatwoot/add-canned-message`,
        "post",
        requestObj
      ).then(({ res }) => {
        if (res && res.code === "OK") {
          message.success(res.message);
          updateModalData({
            visible: false,
            success: true,
          });
        }
      });
    }
    setLoading(false);
  };
  const removeRecord = (record) => {
    Modal.confirm({
      title: "Do you want to delete this canned message?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        APIService.chatwootAPI(
          `api/v1/chatwoot/remove-canned-message`,
          "post",
          {
            _id: record._id,
            organizationId: record.organizationId,
          }
        ).then(({ res }) => {
          if (res && res.code === "OK") {
            message.success(res.message);
            setCannedMessages(
              cannedMessagesData.filter((v) => {
                return v._id !== record._id;
              })
            );
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  function handleSave() {
    if (!isAddNew && display !== "edit") {
      updateModalData({
        visible: false,
        success: true,
      });
      return;
    }
    form
      .validateFields()
      .then((values) => {
        form.resetFields();
        onCreate(values);
        
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  }

  const columns = [
    {
      title: "Short Code",
      dataIndex: "shortCode",
      width: "100px",
    },
    {
      title: "Content",
      dataIndex: "content",
    },
    {
      title: "Actions",
      dataIndex: "id",
      width: "100px",
      render: (text, record) => {
        return (
          <Space>
            <EditOutlined
              style={{ color: "#0a1746" }}
              onClick={() => {
                setEditData(record);
                setDisplay("edit");
              }}
            />
            <DeleteOutlined
              style={{ color: "red" }}
              onClick={() => {
                removeRecord(record);
              }}
            />
          </Space>
        );
      },
    },
  ];
  
  return (
    <Modal
      visible={modalData.visible}
      title={
        <Space>
          <div>Canned Messages</div>{" "}
          {/* <Button shape="circle" onClick={() => setDisplay("form")}>
            <PlusOutlined />
          </Button> */}
        </Space>
      }
      okText="Save"
      cancelText="Cancel"
      width={700}
      confirmLoading={loading}
      onCancel={() => updateModalData({ visible: false })}
      onOk={handleSave}
      footer={!isAddNew && display !== "edit" ? null : undefined}
    >
      {display === "list" && !isAddNew ? (
        <Table
          dataSource={cannedMessagesData}
          columns={columns}
          scroll={{ y: 240 }}
          pagination={false}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={editData}
        >
          <Form.Item
            name="shortCode"
            label="Short Code"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input disabled={!!editData?._id} />
          </Form.Item>
          <Form.Item
            name="content"
            label="Content"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input.TextArea />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
}

import React, { useState, useEffect } from "react";

//antd
import {
    Layout,
    Menu,
    Row,
    Space,
    Col,
    Avatar,
    Dropdown,
    // Button,
    // Badge,
    // Typography,
    Select,
    // Modal,
    // Spin,
    Input,
} from "antd";
import { HomeOutlined } from "@ant-design/icons";
import StorageService from "../services/storage";
//redux actions
import { Link, useHistory } from "react-router-dom";
import find from "lodash/find";
// import debounce from "lodash/debounce";
// import filter from "lodash/filter";

import { MENU } from "../constants/common";
import CommonService from "../services/common";
import APIService from "../services/api";
const { Header, Content, Sider } = Layout;
// const { Title } = Typography;
const loggedInUser = StorageService.getData("user");
// const { Option } = Select;

function AdminLayout({ children }) {
    const [selectedKeys, updateSelectedKeys] = useState(["1"]);
    const [organizationList, setOrganizationList] = useState([]);
    const [selectedOrg, updateSelectedOrg] = useState();

    let history = useHistory();
    useEffect(() => {
        listenRouterChangeEvent();
        getOrganizationsData();
    }, []);
    const getOrganizationsData = () => {
        APIService.request(
            "api/v1/organization/user/organization-list",
            "get"
        ).then(({ res }) => {
            if (res && res.code === "OK") {
                updateSelectedOrg(res.data[0]._id);
                setOrganizationList(res.data);
            }
        });
    };

    const listenRouterChangeEvent = () => {
        let activeMenu = find(MENU, { link: window.location.pathname });
        if (activeMenu) {
            updateSelectedKeys([activeMenu.id.toString()]);
        }
    };

    const menu = (
        <Menu>
            <Menu.Item key="1">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a 
                    onClick={() => {
                        StorageService.clearAllData();
                        history.push(`/login`);
                    }}
                >
                    Logout
                </a>
            </Menu.Item>
        </Menu>
    );

    if (!StorageService.getData("user")) {
        history.push(`/login`);
        return null;
    } else {
        return (
            <Layout className="admin-layout">
                <Sider
                    style={{
                        overflow: "auto",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                    }}
                >
                    <div className="logo">
                        <div>
                            <img
                                style={{ width: "100%" }}
                                src={require("../assets/image/logo.png")}
                                alt=""
                            ></img>
                        </div>
                    </div>
                    <Menu
                        theme="dark"
                        mode="inline"
                        selectedKeys={selectedKeys}
                    >
                        {MENU.map((menu) => {
                            return (
                                <Menu.Item
                                    key={menu.id}
                                    icon={menu.icon}
                                    onClick={() => {
                                        updateSelectedKeys([
                                            menu.id.toString(),
                                        ]);
                                    }}
                                >
                                    <Link to={menu.link}>{menu.label}</Link>
                                </Menu.Item>
                            );
                        })}
                        <Menu.Item
                            key={"jobs"}
                            onClick={() => {
                                window.location.href = `${
                                    window.location.origin.indexOf(
                                        "localhost"
                                    ) > -1
                                        ? "http://localhost:8009"
                                        : window.location.origin
                                }/bull-queue-jobs/${StorageService.getData(
                                    "token"
                                )}`;
                            }}
                        >
                            Bull Queue
                        </Menu.Item>
                    </Menu>
                </Sider>
                <Layout style={{ padding: "0 24px 24px" }}>
                    <Header>
                        <Row type="flex" justify="space-between" align="middle">
                            <Col>
                                <Input.Group>
                                    <HomeOutlined />
                                    <Select
                                        style={{ width: 200 }}
                                        placeholder="Select an Organization"
                                        optionFilterProp="children"
                                        onChange={(newVal) =>
                                            updateSelectedOrg(newVal)
                                        }
                                        value={selectedOrg}
                                        bordered={false}
                                    >
                                        {organizationList &&
                                            organizationList.map((v) => {
                                                return (
                                                    <Select.Option key={v._id}>
                                                        {v.name}
                                                    </Select.Option>
                                                );
                                            })}
                                    </Select>
                                </Input.Group>
                            </Col>
                            <Col>
                                <Row justify="end" align="middle">
                                    <Space>
                                        <Dropdown
                                            overlay={menu}
                                            trigger={["click"]}
                                        >
                                            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                            <a
                                                className="ant-dropdown-link"
                                                onClick={(e) =>
                                                    e.preventDefault()
                                                }
                                            >
                                                <Avatar
                                                    style={{
                                                        color: "#f56a00",
                                                        backgroundColor:
                                                            "#fde3cf",
                                                    }}
                                                >
                                                    {CommonService.initial(
                                                        loggedInUser?.name
                                                    )}
                                                </Avatar>
                                            </a>
                                        </Dropdown>
                                    </Space>
                                </Row>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        style={{
                            padding: 24,
                            marginLeft: "200px",
                            minHeight: 280,
                        }}
                        className="site-layout-background"
                    >
                        {children}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default AdminLayout;

import React, { useEffect, useState } from "react";
import { Table, Input, Space, Button, Typography, message } from "antd";
import APIService from "../../services/api";
import CommonService from "../../services/common";
const { Title } = Typography;
const { Search } = Input;
function SchedulerJobs() {
  const [tableData, updateTableData] = useState({
    loading: false,
    dataSource: [],
    pagination: {
      current: 1,
      pageSize: 10,
      total: 0,
    },
  });
  const [searchQuery, updateSearchQuery] = useState(undefined);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Next Run At",
      dataIndex: "nextRunAt",
      render: (text) => {
        return CommonService.formatDate(text);
      },
    },
    {
      title: "Repeat Interval",
      dataIndex: "repeatInterval",
    },
    {
      title: "Last Run at",
      dataIndex: "lastRunAt",
      render: (text) => {
        return CommonService.formatDate(text);
      },
    },
    {
      title: "Last Finished at",
      dataIndex: "lastFinishedAt",
      render: (text) => {
        return CommonService.formatDate(text);
      },
    },
    {
      title: "Actions",
      dataIndex: "name",
      render: (text) => {
        return (
          <Space>
            <Button type="primary" onClick={() => runNow(text)}>
              Run Now
            </Button>
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    getData(tableData.pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    getData(tableData.pagination);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const runNow = (schedulerName) => {
    APIService.requestWithToken("api/v1/agenda-jobs/run-now", "post", {
      name: schedulerName,
    }).then(({ res }) => {
      if (res && res.code === "OK") {
        message.success("Success");
        getData(tableData.pagination);
      }
    });
  };
  const getData = (pagination) => {
    updateTableData({
      ...tableData,
      pagination: {
        ...pagination,
      },
      loading: true,
      dataSource: [],
    });
    let queryString = CommonService.serialize({
      pageNo: parseInt(pagination.current),
      limit: pagination.pageSize,
      query: searchQuery ? JSON.stringify(searchQuery) : null,
      sort: JSON.stringify({ lastFinishedAt: -1 }),
    });
    APIService.requestWithToken("api/v1/agenda-jobs" + queryString, "get",).then(({ res }) => {
      if (res && res.code === "OK") {
        updateTableData({
          loading: false,
          dataSource: res.data.list,
          pagination: {
            ...pagination,
            total: res.data.count,
          },
        });
      }
    });
  };

  return (
    <div className="df fdc">
      <div className="df jsb">
        <Title level={3}> Scheduler Jobs</Title>
        <Search
          placeholder="input search text"
          onSearch={(searchStr) => {
            updateSearchQuery({
              name: { $regex: searchStr, $options: "i" },
            });
          }}
          style={{ width: 200 }}
        />
      </div>
      <Table {...tableData} columns={columns} onChange={getData}></Table>
    </div>
  );
}

export default SchedulerJobs;

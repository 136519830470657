import io from "socket.io-client";
let socket;
let listen = false;
export const initiateSocket = (userId, orgId) => {
    listen = false;
    // let loggedInUser = StorageService.getData("user");
    // let baseUrl =
    //     window.location.origin.indexOf("localhost") > -1
    //         ? "http://localhost:8009"
    //   : window.location.origin;
    let baseUrl = window.location.origin;
    socket = io(`${baseUrl}/chat`, {
        transports: ["websocket"],
        query: {
            userId: userId,
            orgId: orgId,
        },
    });
    console.log(`Connecting socket...`);
    socket.on("connect", () => {
        socket.emit("test", orgId);
        if (listen) {
            console.log("listen again");
            newMessageReceived(listen);
        }
    });
    socket.on("test", (string) => {
        console.info(string);
    });
    socket.on("welcome", (string) => {
        console.log("welcome", string);
    });
    socket.on("disconnect", () => {
        console.info("disconnect");
    });
};
export const disconnect = () => {
    if (socket) {
        socket.disconnect();
    }
};
export const newMessageReceived = (cb) => {
    if (socket) {
        socket.on("newMessageReceived", cb);
    } else {
        listen = cb;
    }
};

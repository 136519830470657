import React, { useState } from "react";
import { Card, Row, Col, Input, Button } from "antd";
import APIService from "../services/api";
export default function VoiceCall() {
    const [appointmentReminderNumber, setAppointmentReminder] = useState("");
    const [awvCampaignNumber, setAwvCampaignNumber] = useState("");
    const makeCall = (type, number) => {
        APIService.requestWithToken(
            `api/v1/voice-call/`,
            "post",
            {
                "number":number,
                type:type
            }
        )
    };

    return (
        <Row justify="space-around">
            <Col>
                <Card title="Appointment Reminder" style={{ width: 300 }}>
                    <Input
                        style={{ marginBottom: "20px" }}
                        type="text"
                        placeholder="+14698179840"
                        value={appointmentReminderNumber}
                        onChange={(e) => setAppointmentReminder(e.target.value)}
                    />
                    <Button
                        type="primary"
                        onClick={() =>
                            makeCall(
                                "appointment-reminder",
                                appointmentReminderNumber
                            )
                        }
                    >
                        Receive call
                    </Button>
                </Card>
            </Col>
            <Col>
                <Card title="AWV Campaign" style={{ width: 300 }}>
                    <Input
                        style={{ marginBottom: "20px" }}
                        type="text"
                        placeholder="+14698179840"
                        value={awvCampaignNumber}
                        onChange={(e) => setAwvCampaignNumber(e.target.value)}
                    />
                    <Button
                        type="primary"
                        onClick={() =>
                            makeCall("awv-campaign", awvCampaignNumber)
                        }
                    >
                        Receive call
                    </Button>
                </Card>
            </Col>
        </Row>
    );
}

import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import common from "./common";

const reducers = combineReducers({
  routerReducer,
  common,
  composeWithDevTools: composeWithDevTools(),
});

export default reducers;

import React, { useState, useEffect } from "react";
import { Row, Col, Spin } from "antd";
import { useParams } from "react-router-dom";
import APIService from "../../services/api";
import { initiateSocket, disconnect } from "../../services/socket";
import CommonService from "../../services/common";
import StorageService from "../../services/storage";
import ChatConversation from "../Chatwoot/ChatConversation";
function getParameterByName(name, url = window.location.href) {
//eslint-disable-next-line
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}
export default function ChatConversationInFrame() {
  let { patientImportId, userId, organizationId } = useParams();
  const conversationId = getParameterByName("conversationId");
  StorageService.setData("userId", userId);
  const [chatThread, updateChatThread] = useState({
    chatThread: null,
    loading: false,
  });
  useEffect(() => {
    initiateSocket(userId, organizationId);
    getThread();
    return () => {
      disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const getThread = () => {
    updateChatThread({
      ...chatThread,
      loading: true,
    });
    let queryString = CommonService.serialize({
      organizationId,
      patientImportId,
      conversationId,
    });
    APIService.chatwootAPI(
      `api/v1/chatwoot/conversation${queryString}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateChatThread({
          chatThread: { ...res.data },
          loading: false,
        });
      }
    });
  };
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  if (!inIframe()) {
    return <span>You are not authorized to access this page</span>;
  }
  return (
    <>
      {!chatThread.loading && chatThread.chatThread ? (
        <ChatConversation
          organizationId={organizationId}
          chatThread={chatThread.chatThread}
          userId={userId}
        />
      ) : (
        <Row style={{ height: "100%", width: "100%" }} justify="center">
          <Col>
            <Spin spinning={chatThread.loading}></Spin>
          </Col>
        </Row>
      )}
    </>
  );
}

import React from "react";
import { AppstoreOutlined, UserOutlined } from "@ant-design/icons";
export const PRIMARY_COLOR = "#1DA57A";
export const DANGER_COLOR = "#ff4d4f";
export const SUCCESS_COLOR = "#52c41a";
export const INFO_COLOR = "#1890ff";
export let INTAKE_GRAPHQL_URL;
export let INTAKE_FRONTEND_URL;

if (window.location.hostname === "campaigns.steerhealthio" || window.location.hostname === "campaigns.oculushealth.com") {
    INTAKE_GRAPHQL_URL = "https://intake-service.steerhealth.io/graphql";
    INTAKE_FRONTEND_URL = "https://intake.steerhealth.io";
} else {
    INTAKE_GRAPHQL_URL = "https://intake-service.dev.steerhealth.io/graphql";
    INTAKE_FRONTEND_URL = "https://intake.dev.steerhealth.io";
}
export const BASE_URL = "http://localhost:8009";
export const MOBILE_NUMBER_REGEX = /^\d{10}$/g;
export const MENU = [
    {
        id: 1,
        label: "Scheduler Jobs",
        link: "/app/scheduler-jobs?token=689c7146-b1b3-4dd8-bdeb-40836ddb1403",
        icon: <AppstoreOutlined></AppstoreOutlined>,
    },
    {
        id: 2,
        label: "User Management",
        link: "/app/user-management?token=689c7146-b1b3-4dd8-bdeb-40836ddb1403",
        icon: <UserOutlined />,
    },
    {
        id: 3,
        label: "Test Voice Call",
        link: "/app/voice-call?token=689c7146-b1b3-4dd8-bdeb-40836ddb1403",
        icon: <UserOutlined />,
    },
];
export const INR = "₹";

export const userGroups = {
    administrator: "administrator", // -> keys are organizationIds
    provider: "provider", // -> keys are organizationIds
    caregiver: "caregiver", // -> keys are userIds
    team: "team",
    doctor: "doctor", // keys are organizationIds
    accountManager: "accountManager", // -> keys are organizationIds
    officeStaff: "officeStaff", // -> keys are organizationIds
    medicalAssistant: "medicalAssistant", // -> keys are organizationIds
    officeAdmin: "officeAdmin",
};
export const TOKEN_RESTRICTED_PATH = ["chatwoot-frame", "chatthread-frame"];

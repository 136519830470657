import React, { useEffect, useState } from "react";
import {
  Layout,
  // List,
  // Empty,
  Typography,
  Spin,
  // Avatar,
  Row,
  Col,
  Input,
  Card,
  // Badge,
  Form,
  Button,
  Select,
  Popover,
  Badge,
  // Tag,
  // Space,
  Modal,
  Radio,
} from "antd";
import moment from "moment";
import { InView } from "react-intersection-observer";
import {
  // UserAddOutlined,
  // SettingFilled,
  SearchOutlined,
} from "@ant-design/icons";

import {
  initiateSocket,
  newMessageReceived,
  disconnect,
} from "../../services/socket";
import PersonIcon from "../../assets/image/person.svg";
// import DocumentAddIcon from "../../assets/image/document-add.svg";
import ChatConversation from "./ChatConversation";
import ConversationList from "./ConversationList";
import EmptyConversation from "./EmptyConversation";
import CommonService from "../../services/common";
import APIService from "../../services/api";
// import birthdateIcon from "../../assets/image/birthdate-icon.png";
import SettingsIcon from "../../assets/image/settings.svg";
import AddCannedMessageModal from "./AddCannedMessageModal";
import useDebounce from "../../Common/hooks/useDebounce";

const { Sider } = Layout;
// const {  Text, Paragraph } = Typography;
const { Text } = Typography;

let globalChatThreadData;
let timer;

// function getUrlParameter(name) {
//   name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
//   let regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
//   let results = regex.exec(window.location.search);
//   return results === null
//     ? ""
//     : decodeURIComponent(results[1].replace(/\+/g, " "));
// }
function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

function cleanPhoneNumber(phoneNumber) {
  // Remove parentheses, spaces, and dashes
  const cleanedNumber = phoneNumber.replace(/\D/g, "");
  // Ensure the number is 10 digits long
  const formattedNumber = cleanedNumber.substring(0, 10);
  return formattedNumber;
}

const requestStatusBgColor = {
  Clinical: "#eb34b4",
  Request: "#db9a0d",
  Urgent: "red",
  Billing: "#6c6e05",
  Scheduling: "#b54905",
  Referral: "#4287f5",
};

function Chatwoot({ accountId, userId, organizationId, conversationId }) {
  const [searchUsers, updateSearchUsers] = useState([]);
  const initialTableData = {
    loading: false,
    data: [],
  };
  const [chatThreadData, updateChatThreadData] = useState(initialTableData);
  const [chatThreadPagination, updateChatThreadPagination] = useState({
    current: 0,
  });
  const [selectedConversationStatus, updateSelectedConversationStatus] =
    useState(["open", "unassigned"]);
  const [assignedFilter, setAssignedFilter] = useState("all");
  const [isRequestAssign, setIsRequestAssign] = useState(false);
  const [isStaffAssign, setIsStaffAssign] = useState(false);
  const [searchUserInList, setSearchUserInList] = useState("");
  const debouncedSearchTerm = useDebounce(searchUserInList, 500);

  const [endReached, updateEndReached] = useState(false);
  const [activeThread, updateActiveThread] = useState({
    chatThread: null,
    loading: false,
  });
  const [orgDetails, setOrgDetails] = useState();

  const error = () => {
    Modal.error({
      title: "Oops ! wrong format",
      content:
        "Invalid format. Please check for special characters or extra spaces.",
    });
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    const isPhoneNumber = /^\(\d{3}\)\s*\d{3}-\d{4}$/.test(inputValue);
    // Check if the input is alphanumeric (excluding special characters, allow spaces)
    const isAlphanumeric = /^[a-zA-Z0-9\s]+$/.test(inputValue);

    if (isPhoneNumber) {
      // If it's a phone number, format it
      const formattedNumber = cleanPhoneNumber(inputValue);
      setSearchUserInList(formattedNumber);
    } else if (isAlphanumeric) {
      // If it's alphanumeric, assume it's a name and keep it as-is
      setSearchUserInList(inputValue.trim());
    } else if (inputValue === "") {
      setSearchUserInList("");
    } else {
      error();
    }
  };

  useEffect(() => {
    initiateSocket(userId, organizationId);
    //subscribe to chat thread
    newMessageReceived((newMessage) => {
      if (newMessage?.message?.type === "outgoing") {
        const msgConversation = globalChatThreadData.data.find(
          (item) => item._id === newMessage.conversationId
        );
        const filteredConversation = globalChatThreadData.data.filter(
          (item) => item._id !== newMessage.conversationId
        );
        updateChatThreadData({
          ...globalChatThreadData,
          data: [
            { ...msgConversation, updatedAt: moment().toISOString() },
            ...filteredConversation,
          ],
        });
        return;
      }

      let updatedData = globalChatThreadData.data.map((v) => {
        return v._id === newMessage.conversationId
          ? {
              ...v,
              lastMessage: newMessage.message.message,
              lastMessageId: newMessage.message._id,
              updatedAt: newMessage.message.updatedAt,
              unreadCount: v.unreadCount ? v.unreadCount + 1 : 1,
            }
          : { ...v };
      });
      updateChatThreadData({
        ...chatThreadData,
        data: updatedData,
      });
    });
    getSearchUsers();
    return () => {
      disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    globalChatThreadData = { ...chatThreadData };
  }, [chatThreadData]);

  useEffect(() => {
    if (selectedConversationStatus || assignedFilter || debouncedSearchTerm) {
      updateChatThreadData(initialTableData);
      updateChatThreadPagination({ current: 1 });
      setFilterPopover(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedConversationStatus, assignedFilter, debouncedSearchTerm]);

  useEffect(() => {
    if (
      chatThreadPagination &&
      chatThreadPagination.current &&
      !chatThreadData.loading
    ) {
      getConversations(debouncedSearchTerm);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatThreadPagination, debouncedSearchTerm]);

  const getSearchUsers = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/search-users?organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateSearchUsers(res.data);
      }
    });
  };

  const handleLoadMore = (inView) => {
    if (!chatThreadData.loading && inView && !endReached) {
      updateChatThreadPagination({
        current: chatThreadPagination.current + 1,
      });
    }
  };

  const getConversations = (debouncedSearchTerm = "") => {
    if (chatThreadPagination.current === 1) {
      updateActiveThread({
        chatThread: null,
        loading: false,
      });
    }
    updateChatThreadData({
      ...chatThreadData,
      loading: true,
    });
    let queryString;
    if (assignedFilter === "assignedToMe") {
      queryString = `?organizationId=${organizationId}&status=${JSON.stringify(
        selectedConversationStatus
      )}&page=${
        chatThreadPagination.current
      }&limit=10&userId=${userId}&search=${debouncedSearchTerm}`;
    } else {
      queryString = `?organizationId=${organizationId}&status=${JSON.stringify(
        selectedConversationStatus
      )}&page=${
        chatThreadPagination.current
      }&limit=10&search=${debouncedSearchTerm}`;
    }

    if (chatThreadPagination.current === 1) {
      updateEndReached(false);
    }
    APIService.chatwootAPI(
      `api/v1/chatwoot/conversations${queryString}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        let threads = res.data;
        if (threads && threads.length) {
          if (chatThreadPagination.current === 1) {
            if (!conversationId) {
              updateActiveThread({
                chatThread: threads[0],
                loading: false,
              });
            }
            updateChatThreadData({
              ...chatThreadData,
              loading: false,
              data: threads,
            });
          } else {
            updateChatThreadData({
              ...chatThreadData,
              loading: false,
              data: [...chatThreadData.data, ...threads],
            });
          }
          if (threads.length < 10) {
            updateEndReached(true);
          }
        } else {
          if (chatThreadPagination.current === 1) {
            updateChatThreadData({
              data: [],
              loading: false,
            });
          } else {
            updateChatThreadData({
              ...chatThreadData,
              loading: false,
            });
          }
          updateEndReached(true);
        }
      }
    });

    APIService.requestWithToken(
      `api/v1/organization/${organizationId}`,
      "get",
      organizationId
    )
      .then(({ res }) => {
        if (res && res.code === "OK") {
          setOrgDetails(res.data);
        }
      })
      .catch((err) => console.log(err));
  };
  const onSearchResultSelect = (patientId) => {
    let user = searchUsers.find((v) => {
      return v._id === patientId;
    });
    updateActiveThread({
      chatThread: null,
      loading: true,
    });
    let queryString = CommonService.serialize({
      organizationId,
      patientImportId: user._id,
    });
    APIService.chatwootAPI(
      `api/v1/chatwoot/conversation${queryString}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        let thread = { ...res.data };
        updateActiveThread({
          chatThread: thread,
          loading: false,
        });
        let exist = chatThreadData.data.find((v) => {
          return v._id === thread._id;
        });
        if (!exist) {
          updateChatThreadData({
            ...chatThreadData,
            data: [{ ...res.data }, ...chatThreadData.data],
          });
        }
      }
    });

    let queryStringToGetAppointmentDetails = CommonService.serialize({
      organizationId,
      patientId: user._id,
    });

    APIService.appointmentAPI(
      `api/v1/appointments/due-date-list${queryStringToGetAppointmentDetails}`,
      "get",
      null,
      organizationId
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        let sortedAppointmentArr =
          res.data && res.data.length
            ? res.data
                .sort(function (a, b) {
                  return (
                    new Date(a.appointmentTime) - new Date(b.appointmentTime)
                  );
                })
                .reverse()
            : [];

        setAppointmentDetails(sortedAppointmentArr);
      }
    });
  };

  const markUnreadAsRead = (thread) => {
    updateChatThreadData({
      ...chatThreadData,
      data: chatThreadData.data.map((v) => {
        return v._id === thread._id ? { ...v, unreadCount: 0 } : { ...v };
      }),
    });
  };
  const callback = () => {
    getConversations(debouncedSearchTerm);
  };
  // function initials(str) {
  //   let result = "";
  //   if (str) {
  //     let splitArr = str.split(" ");
  //     splitArr.forEach((e) => {
  //       result += e.substr(0, 1);
  //     });
  //   }
  //   return result;
  // }

  function handleMarkAsUnread(chatData) {
    const modifiedChatThreadData = chatThreadData?.data.map((item) =>
      item._id === chatData._id ? chatData : item
    );
    updateChatThreadData({ data: modifiedChatThreadData, loading: false });
  }

  // const ListItemMeta = ({ chatThread }) => {
  //   let obj = { ...chatThread };
  //   return (
  //     <>
  //       <List.Item.Meta
  //         key={obj._id}
  //         onClick={() => {
  //           markUnreadAsRead(obj);
  //           if (activeThread && activeThread.chatThread._id === obj._id) {
  //             return;
  //           }
  //           updateActiveThread({ chatThread: null, loading: true });
  //           setTimeout(() => {
  //             updateActiveThread({
  //               chatThread: obj,
  //               loading: false,
  //             });
  //           }, 300);
  //         }}
  //         avatar={
  //           <Avatar
  //             size={38}
  //             style={{
  //               color: "#ffffff",
  //               backgroundColor: "#0A1746",
  //             }}
  //           >
  //             {initials(obj.patientName)}
  //           </Avatar>
  //         }
  //         title={
  //           <div className="df am">
  //             <Text strong={obj.unreadCount}>{obj.patientName}</Text>
  //             {obj.birthdate ? (
  //               <Tooltip
  //                 overlayClassName="birthdate-tooltip"
  //                 title={
  //                   <Text strong={obj.unreadCount}>
  //                     {obj.birthdate ? `DoB : ${obj.birthdate}` : ""}
  //                   </Text>
  //                 }
  //               >
  //                 <img
  //                   style={{
  //                     width: "18px",
  //                     height: "18px",
  //                     marginLeft: "5px",
  //                   }}
  //                   src={birthdateIcon}
  //                 />
  //               </Tooltip>
  //             ) : null}
  //           </div>
  //         }
  //         description={
  //           <Text strong={obj.unreadCount}>
  //             <Paragraph ellipsis={true}>{obj.lastMessage}</Paragraph>
  //           </Text>
  //         }
  //       />
  //       <div className="df fdc ab">
  //         <Space className="df fdc ab">
  //           <Text>{moment(obj.updatedAt).fromNow()}</Text>
  //           <Tag style={{ textTransform: "uppercase" }}>{obj.status}</Tag>
  //           <span>
  //             <Badge count={obj.unreadCount}></Badge>
  //           </span>
  //         </Space>
  //       </div>
  //     </>
  //   );
  // };
  function hideScroll() {
    var el = document.querySelector(".chat-list");
    el.classList.add("scroll");
    clearTimeout(timer);
    timer = setTimeout(function () {
      el.classList.remove("scroll");
    }, 100);
  }
  function ChatFilter() {
    return (
      <Form layout="vertical">
        {/* <div className="df fdc jsb">
          <Form.Item label="Filter by Status" style={{ marginBottom: "10px" }}>
            <Select
              mode="multiple"
              placeholder="Select Status"
              style={{ minWidth: "100px" }}
              value={selectedConversationStatus}
              onChange={updateSelectedConversationStatus}
            >
              <Select.Option value="open">Open</Select.Option>
              <Select.Option value="resolved">Resolved</Select.Option>
              <Select.Option value="unassigned">UnAssigned</Select.Option>
            </Select>
          </Form.Item>
        </div> */}

        <div className="df fdc jsb">
          <Form.Item
            label="Filter by Assigned Staff"
            style={{ marginBottom: "10px" }}
          >
            <Select
              placeholder="Select Status"
              style={{ minWidth: "100px" }}
              value={assignedFilter}
              onChange={setAssignedFilter}
            >
              <Select.Option value="assignedToMe">Assigned to me</Select.Option>
              <Select.Option value="all">All</Select.Option>
            </Select>
          </Form.Item>
        </div>

        <p>Canned Messages</p>

        <Button
          style={{
            background: "#0a1746",
            color: "#fff",
            borderRadius: "5px",
            margin: "1px",
          }}
          onClick={() => {
            updateCannedMessageModal({
              visible: true,
              organizationId: organizationId,
            });
            setIsAddNew(false);
            setFilterPopover(!filterPopover);
          }}
        >
          Manage messages
        </Button>
        <Button
          style={{
            background: "#0a1746",
            color: "#fff",
            borderRadius: "5px",
            margin: "1px",
          }}
          onClick={() => {
            updateCannedMessageModal({
              visible: true,
              organizationId: organizationId,
            });
            setIsAddNew(true);
            setFilterPopover(!filterPopover);
          }}
        >
          Add new messages
        </Button>
      </Form>
    );
  }
  const [filterPopover, setFilterPopover] = useState(false);
  const [cannedMessageModal, updateCannedMessageModal] = useState({
    visible: false,
  });
  const [isAddNew, setIsAddNew] = useState(false);
  const [cannedOptions, updateCannedOptions] = useState([]);
  const [appointmentDetails, setAppointmentDetails] = useState();
  const [appointmentLoading, setAppointmentLoading] = useState(false);

  useEffect(() => {
    getCannedMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      activeThread &&
      activeThread.chatThread &&
      activeThread.chatThread.patientId
    ) {
      updateActiveThread({
        chatThread: null,
        loading: true,
      });
      let queryString = CommonService.serialize({
        organizationId,
        patientImportId: activeThread.chatThread.patientId,
      });
      APIService.chatwootAPI(
        `api/v1/chatwoot/conversation${queryString}`,
        "get"
      ).then(({ res }) => {
        if (res && res.code === "OK") {
          let thread = { ...res.data };
          updateActiveThread({
            chatThread: thread,
            loading: false,
          });
          let exist = chatThreadData.data.find((v) => {
            return v._id === thread._id;
          });
          if (!exist) {
            updateChatThreadData({
              ...chatThreadData,
              data: [{ ...res.data }, ...chatThreadData.data],
            });
          }
        }
      });
      setIsRequestAssign(false);
      setIsStaffAssign(false);
    }
  }, [isRequestAssign, isStaffAssign]);

  useEffect(() => {
    if (!cannedMessageModal.visible && cannedMessageModal.success) {
      getCannedMessages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cannedMessageModal]);

  const getCannedMessages = () => {
    APIService.chatwootAPI(
      `api/v1/chatwoot/canned-messages?organizationId=${organizationId}`,
      "get"
    ).then(({ res }) => {
      if (res && res.code === "OK") {
        updateCannedOptions(res.data);
      }
    });
  };

  useEffect(() => {
    if (
      activeThread &&
      activeThread.chatThread &&
      activeThread.chatThread.patientId
    ) {
      setAppointmentLoading(true);
      let queryStringToGetAppointmentDetails = CommonService.serialize({
        organizationId,
        patientId: activeThread.chatThread.patientId,
      });

      APIService.appointmentAPI(
        `api/v1/appointments/due-date-list${queryStringToGetAppointmentDetails}`,
        "get",
        null,
        organizationId
      ).then(({ res }) => {
        if (res && res.code === "OK") {
          let sortedAppointmentArr =
            res.data && res.data.length
              ? res.data
                  .sort(function (a, b) {
                    return (
                      new Date(a.appointmentTime) - new Date(b.appointmentTime)
                    );
                  })
                  .reverse()
              : [];
          setAppointmentDetails(sortedAppointmentArr);
          setAppointmentLoading(false);
        }
      });
    } else {
      setAppointmentDetails([]);
    }
  }, [activeThread.chatThread]);

  const isSelected = (status) => {
    if (status === "open/unassign") {
      // Check if both "open" and "unassigned" are selected
      return selectedConversationStatus.includes("open") && selectedConversationStatus.includes("unassigned");
    }
    // For individual statuses, check if the status is included in the selectedConversationStatus array
    return selectedConversationStatus.length === 1 && selectedConversationStatus.includes(status);
  };

  return (
    <Card className="chat-card">
      <Layout className="chat-layout">
        <Sider className="chat-siderbar">
          <div className="conversation-header">
            <Radio.Group size="small">
              {["open", "unassigned", "resolved", "open/unassign"].map(
                (status) => (
                  <Radio.Button
                    key={status}
                    style={{
                      borderRadius: "1px",
                      marginBottom: "2px",
                      color: isSelected(status) ? "#fff" : "#0a1746",
                      backgroundColor: isSelected(status)
                        ? "#0a1746"
                        : undefined,
                      borderColor: isSelected(status) ? "#0a1746" : undefined,
                    }}
                    onClick={() => {
                      status === "open/unassign"
                        ? updateSelectedConversationStatus([
                            "open",
                            "unassigned",
                          ])
                        : updateSelectedConversationStatus([status]);
                    }}
                  >
                    {status.charAt(0).toUpperCase() + status.slice(1)}{" "}
                  </Radio.Button>
                )
              )}
            </Radio.Group>

            <Row justify="space-between">
              <Col span={21}>
                <Select
                  showSearch
                  className="chat-search"
                  placeholder={
                    <div className="df jsb am">
                      <span style={{ color: "#0a1746", fontWeight: "bold" }}>
                        Start a new chat
                      </span>
                      <SearchOutlined />
                    </div>
                  }
                  allowClear={true}
                  style={{ width: "100%" }}
                  showArrow={false}
                  onSelect={onSearchResultSelect}
                  filterOption={(input, option) => {
                    return (
                      option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  notFoundContent={null}
                >
                  {searchUsers.map((user) => (
                    <Select.Option
                      key={`${user._id} ${user.firstName} ${user.lastName} ${user.phoneNumber} ${user.birthdate}`}
                      value={user._id}
                    >
                      <Row justify="space-between">
                        <Text>
                          {user.firstName} {user.lastName}{" "}
                        </Text>
                        <Text>
                          {user.phoneNumber
                            ? formatPhoneNumber(user.phoneNumber)
                            : ""}{" "}
                        </Text>
                      </Row>
                    </Select.Option>
                  ))}
                </Select>
              </Col>
              <Col span={2}>
                <Popover
                  placement="bottomRight"
                  content={<ChatFilter></ChatFilter>}
                  title="Settings"
                  trigger="click"
                  visible={filterPopover}
                  onVisibleChange={() => setFilterPopover(!filterPopover)}
                >
                  <Button shape="circle">
                    <img src={SettingsIcon} alt="" />
                  </Button>
                </Popover>
              </Col>
            </Row>
            <Row className="m-t-5">
              <Input
                title={"Search by user name/phone number"}
                value={searchUserInList}
                onChange={handleInputChange}
                placeholder={`${assignedFilter === "assignedToMe" ? `Search ${selectedConversationStatus} chat assigned to you`: `Search ${selectedConversationStatus} chat`}`}
              />
            </Row>
          </div>
          <Row>
            <Col span={24} className="m-t-5">
              <div onScroll={hideScroll} className="chat-list">
                <ConversationList
                  data={chatThreadData?.data}
                  activeThread={activeThread}
                  markUnreadAsRead={markUnreadAsRead}
                  updateActiveThread={updateActiveThread}
                  organizationId={organizationId}
                  conversationId={conversationId}
                  orgDetails={orgDetails ? orgDetails : null}
                />
                {!endReached && (
                  <InView as="div" onChange={handleLoadMore}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: 20,
                      }}
                    >
                      <Spin spinning={chatThreadData.loading}></Spin>
                    </div>
                  </InView>
                )}
              </div>
            </Col>
          </Row>
        </Sider>
        {!activeThread.loading ? (
          activeThread.chatThread ? (
            <div style={{ width: "100%", display: "flex" }}>
              <ChatConversation
                organizationId={organizationId}
                userId={userId}
                chatThread={activeThread.chatThread}
                callback={callback}
                markAsUnread={handleMarkAsUnread}
                appointmentDetails={appointmentDetails}
                setIsRequestAssign={setIsRequestAssign}
                setIsStaffAssign={setIsStaffAssign}
                assignedFilter={assignedFilter}
                selectedConversationStatus={selectedConversationStatus}
              />

              <Sider className="patient-siderbar">
                <Row justify="space-between">
                  <Col span={21}>
                    {activeThread?.chatThread?.requestType ? (
                      <div
                        className="request-status"
                        style={{
                          background: `${
                            requestStatusBgColor[
                              activeThread?.chatThread?.requestType
                            ]
                          }`,
                          color: "#fff",
                        }}
                      >
                        {activeThread?.chatThread?.requestType}
                      </div>
                    ) : null}
                    <div className="patient-header">
                      <img src={PersonIcon} alt="patient icon" />
                      <p className="patient-heading">
                        {activeThread?.chatThread &&
                          activeThread.chatThread.patientName}
                      </p>
                    </div>
                    <div className="patient-details-content">
                      <strong>D.O.B:</strong>{" "}
                      {activeThread?.chatThread?.birthdate}
                      <br />
                      <strong>MRN:</strong> {activeThread?.chatThread?.mrn}
                      <br />
                      <h3 style={{ marginTop: "10px" }}>Recent Visits</h3>
                      {!appointmentLoading &&
                      appointmentDetails &&
                      appointmentDetails.length ? (
                        appointmentDetails.map((currentAppointment) => (
                          <div
                            key={currentAppointment._id}
                            style={{
                              width: "120%",
                              margin: "5px 0 5px 0",
                              borderRadius: "10px",
                              border: "1px solid lightgrey",
                              padding: "5px",
                              textAlign: "center",
                              boxShadow:
                                "0 2px 2px 0 rgba(0, 0, 0, 0.1), 0 2px 10px 0 rgba(0, 0, 0, 0.19)",
                            }}
                          >
                            <Badge
                              style={{
                                backgroundColor: "#0a1746",
                                fontWeight: "bold",
                                fontSize: "14px",
                              }}
                              count={
                                currentAppointment.appointmentTime
                                  ? moment(
                                      currentAppointment.appointmentTime
                                    ).format("MM/DD/YY, hh:mm A")
                                  : null
                              }
                            ></Badge>
                            <br />
                            <p style={{ color: "rgb(225 10 53)" }}>
                              <strong>Condition: </strong>
                              {currentAppointment.appointmentReason
                                ? currentAppointment.appointmentReason
                                : null}
                            </p>
                          </div>
                        ))
                      ) : appointmentLoading ? (
                        <Spin spinning={appointmentLoading}></Spin>
                      ) : (
                        <p style={{ color: "#888", fontSize: "14px" }}>
                          No appointment
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
              </Sider>
            </div>
          ) : (
            <EmptyConversation organizationId={organizationId} />
          )
        ) : (
          <Row justify="center" align="middle" className="layout-fill">
            <Col>
              <Spin></Spin>
            </Col>
          </Row>
        )}

        {cannedMessageModal.visible ? (
          <AddCannedMessageModal
            modalData={cannedMessageModal}
            updateModalData={updateCannedMessageModal}
            isAddNew={isAddNew}
            cannedMessages={cannedOptions}
          ></AddCannedMessageModal>
        ) : null}
      </Layout>
    </Card>
  );
}

export default Chatwoot;

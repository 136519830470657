import { INTAKE_GRAPHQL_URL } from "../constants/common";
export const encryptQuery = `query encrypt($string: String) {
    encrypt(string: $string)
  }`;

  export async function fetchIntakeGraphqlAPI(query, variables) {
    try {
      const res = await fetch(INTAKE_GRAPHQL_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query,
          variables,
        }),
      });
  
      const json = await res.json();
      if (json.errors) {
         // eslint-disable-next-line
        throw { errors: json.errors };
      }
      return json.data;
    } catch (err) {
      throw err;
    }
  }
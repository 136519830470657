import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    useHistory,
} from "react-router-dom";
import { Result } from "antd";
import { TOKEN_RESTRICTED_PATH } from "./constants/common";
import Login from "./pages/auth/login";
import AdminLayout from "./pages/AdminLayout";
import SchedulerJobs from "./pages/SchedulerJobs/";
import UserManagement from "./pages/UserManagement/index";
import ChatWootInFrame from "./pages/ChatWootInFrame/index";
import TestComponent from "./pages/test/test";
import NotFound from "./pages/NotFound.js";
import ChatConversationInFrame from "./pages/ChatConversationInFrame/index";
import VoiceCall from "./pages/VoiceCall";
// import StorageService from "./services/storage";
import "./App.less";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
    dsn: "https://676d446879be405fb867bd41bd6b6c21@o4505145591791616.ingest.sentry.io/4505400247975936",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});

function App() {
    let history = useHistory();
    let basePath = window.location.pathname;
    basePath = basePath.split("/");
    if (
        TOKEN_RESTRICTED_PATH.indexOf(basePath[1]) > -1 &&
        window.location.search.indexOf(
            "689c7146-b1b3-4dd8-bdeb-40836ddb1403"
        ) === -1
    ) {
        return (
            <Result
                status="403"
                title="403"
                subTitle="Sorry, you are not authorized to access this page."
            />
        );
    }
    if (window.location !== window.parent.location) {
        // The page is in an iframe
    } else {
        // The page is not in an iframe
    }
    return (
        <Router>
            <Switch>
                <Route exact path="/chatwoot-frame/:userId/:organizationId">
                    <ChatWootInFrame />
                </Route>
                <Route
                    exact
                    path="/chatthread-frame/:patientImportId/:userId/:organizationId"
                >
                    <ChatConversationInFrame />
                </Route>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route exact path="/test" component={TestComponent} />
                <Route
                    component={(props) => {
                        if (props.location.pathname.indexOf("app") === -1) {
                            history.push(
                                "/404?token=689c7146-b1b3-4dd8-bdeb-40836ddb1403"
                            );
                            return null;
                        }
                        return (
                            <AdminLayout>
                                <div>
                                    <Route
                                        exact
                                        path="/"
                                        component={SchedulerJobs}
                                    />
                                    <Route
                                        exact
                                        path="/app/scheduler-jobs"
                                        component={SchedulerJobs}
                                    />
                                    <Route
                                        exact
                                        path="/app/user-management"
                                        component={UserManagement}
                                    />
                                    <Route
                                        exact
                                        path="/app/voice-call"
                                        component={VoiceCall}
                                    />
                                </div>
                            </AdminLayout>
                        );
                    }}
                />
                <Route path="/404" component={NotFound} />
            </Switch>
        </Router>
    );
}
export default App;

import React from "react";
// import { Result } from "antd";
import { useParams, useLocation } from "react-router-dom";
import Chatwoot from "../Chatwoot/";
import StorageService from "../../services/storage";

export default function ChatWootInFrame() {
  let { userId, organizationId } = useParams();
  const { search } = useLocation();
  const query = new URLSearchParams(search);

  StorageService.setData("userId", userId);
  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }
  if (!inIframe()) {
    return <span>You are not authorized to access this page</span>;
  }
  return (
    <>
      <Chatwoot
        userId={userId}
        organizationId={organizationId}
        conversationId={
          query.get("conversationId") ? query.get("conversationId") : ""
        }
      />
    </>
  );
}
